import PropTypes, { oneOfType } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Icon, Menu, Sidebar as SemanticSidebar } from "semantic-ui-react";
import styled from "styled-components";

import CreateReviewButton from "./CreateReviewButton";

const propTypes = {
  children: oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  hideSidebar: PropTypes.func,
  isSidebarVisible: PropTypes.bool,
};

const defaultProps = {
  children: [],
  hideSidebar: () => { },
  isSidebarVisible: false,
};

const SidebarItems = [
  {
    text: "Home",
    shortName: "home",
    route: "/"
  },
  {
    text: "Departments",
    shortName: "departments",
    route: "/departments"
  },
  {
    text: "Announcements",
    shortName: "announcements",
    route: "/announcements"
  },
  {
    text: "Newest Reviews",
    shortName: "newestReviews",
    route: "/newestReviews"
  },
  {
    text: "About",
    shortName: "about",
    route: "/about"
  },
  {
    text: "Join the Team",
    shortName: "join the team",
    route: "/join-the-team"
  },
  {
    text: "FAQs",
    shortName: "home",
    route: "/faqs"
  },
  {
    text: "Admin Login",
    shortName: "admin log in",
    route: "/login"
  }
]

export default function Sidebar({ children, hideSidebar, isSidebarVisible }) {
  return (
    <>
      <SemanticSidebar
        borderless
        vertical
        animation="overlay"
        as={Menu}
        visible={isSidebarVisible}
        onHide={hideSidebar}
      >
        <Menu.Item
          as={Link}
          className="sidebar-header"
          name="welcome to culpa"
          to="/"
        >
          WELCOME TO CULPA!
        </Menu.Item>
        {SidebarItems.map((menuItem) => (
          <Menu.Item as={Link} name={menuItem.shortName} to={menuItem.route} onClick={() => hideSidebar()}>
            {menuItem.text}
            <Icon color="blue" name="angle right" size="large" />
          </Menu.Item>
        ))}
        <MobileContainer>
        <Menu.Item name="review button" onClick={() => hideSidebar()}>
          <CreateReviewButton fluid color="orange" />
        </Menu.Item>
        </MobileContainer>
      </SemanticSidebar>
      <SemanticSidebar.Pushable>
        <SemanticSidebar.Pusher>{children}</SemanticSidebar.Pusher>
      </SemanticSidebar.Pushable>
    </>
  );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

const MobileContainer = styled.div`
  display: none;

  @media (max-width: 769px) {
    display: flex;
  }
`

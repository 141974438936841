import PropTypes from "prop-types";
import React, { useState } from 'react';
import { Button, Checkbox, Container, Grid } from "semantic-ui-react";

const propTypesActionMenu = {
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    itemId: PropTypes.number.isRequired
};

const propTypesRadioButton = {
    option: PropTypes.string.isRequired,
};

export default function ActionMenu({ options, handleSubmit, itemId }) {
    const [selectedOption, setSelected] = useState(null);

    const onValueChange = (event) => {
        setSelected(event.target.value)
    }

    const onSubmit = () => {
        handleSubmit(itemId, selectedOption)
    }

    const RadioButton = ({ option }) => {
        return (
            <Container>
                <Checkbox
                    radio
                    checked={selectedOption === option}
                    id={option + itemId}
                    label={option}
                    value={option}
                    onChange={onValueChange}
                />
            </Container>
        )
    }

    RadioButton.propTypes = propTypesRadioButton;

    return (
        <Grid stackable columns={2}>
            <Grid.Column>
                <Container>
                    <Container>
                        {options.map((option) => (
                            <RadioButton key={option} option={option} />
                        ))}
                    </Container>
                    {selectedOption !== null && 
                    <Container>
                        <Button basic onClick={() => setSelected(null)}>clear</Button>
                    </Container>}
                    <br />
                    <Button className="btn btn-default" disabled={selectedOption == null} onClick={() => onSubmit()}>Complete Review</Button>
                </Container>
            </Grid.Column>
        </Grid>
    )
}

ActionMenu.propTypes = propTypesActionMenu;

import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Grid, Header, Image } from "semantic-ui-react";
import styled from "styled-components";

import Banner from "./common/Banner";
import DFPAdSlot from "./common/DFPAdSlot";
import NewestReviewCard from "./reviews/NewestReviewCard";


import useDataFetch from "components/common/useDataFetch";
import culpaIcon from "icons/blue-culpa.svg";
import bronzeNugget from "icons/bronze-nugget.svg";
import goldNugget from "icons/gold-nugget.svg";
import silverNugget from "icons/silver-nugget.svg";
import reviewArrow from "icons/to-review.png";



export default function HomePage() {
  const reviewDataFetched = useDataFetch(`/api/review/get/newest/3`, {
    reviews: [],
  });

  const { reviews } = reviewDataFetched.data;
  const newestReviews = reviews.map((review) => {
    return (
      <Grid.Column>
        <NewestReviewCard
          content={review.content}
          reviewHeader={review.reviewHeader}
          reviewId={review.reviewId}
          submissionDate={review.submissionDate}
        />
      </Grid.Column>
    );
  });

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 800);
  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 800);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Container textAlign="center">
      <Banner />
      <Container style={{ margin: "2vh" }}>
        <DesktopContainer>
          <Image centered spaced size="big" src={culpaIcon} />
        </DesktopContainer>
        <MobileContainer>
          <Image
            centered
            size="big"
            src={culpaIcon}
            style={{ marginBottom: "1rem" }}
          />
        </MobileContainer>
        <Header className="culpa-description" size="large">
          Columbia&apos;s anonymous professor and course rating site.
        </Header>
        <Container
          style={{ margin: "1.5rem 0", width: "fit-content" }}
          textAlign="left"
        >
          <NuggetContainer>
            <Image size="mini" spaced="right" src={goldNugget} />
            <Header className="nugget-description">
              Gold nuggets mean that a professor has been consistently reviewed
              as
              <span style={{ color: "#004E8D" }}> exceptional.</span>
            </Header>
          </NuggetContainer>
          <NuggetContainer>
            <Image size="mini" spaced="right" src={silverNugget} />
            <Header className="nugget-description">
              Silver nuggets mean that a professor has been consistently
              reviewed as
              <span style={{ color: "#004E8D" }}> outstanding.</span>
            </Header>
          </NuggetContainer>
          <NuggetContainer>
            <Image size="mini" spaced="right" src={bronzeNugget} />
            <Header className="nugget-description">
              Bronze nuggets mean that a professor has been consistently
              reviewed as
              <span style={{ color: "#004E8D" }}> great.</span>
            </Header>
          </NuggetContainer>
        </Container>
      </Container>
      <DesktopContainer style={{ marginTop: "3rem" }}>
        <Grid columns={3}>{newestReviews}</Grid>
      </DesktopContainer>
      <MobileContainer style={{ marginTop: "3rem" }}>
        <Grid stackable columns={3}>
          {newestReviews}
        </Grid>
      </MobileContainer>
      <Link
        style={{ fontWeight: "bold", textDecoration: "underline" }}
        to="/newestReviews/"
      >
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "2rem",
          }}
        >
          <Image src={reviewArrow} style={{ height: "2vh" }} />
          View Newest Reviews
        </Container>
      </Link>
      {isLargeScreen ? (
        <>
        <DFPAdSlot height={90} path="culpa_leaderboard" width={728}/>
        </>
      ) : (
        <>
        <DFPAdSlot gi height={250} path="culpa_leaderboard"width={320}/>
        </>
      )}
    </Container>
  );
}

const DesktopContainer = styled.div`
  @media (max-width: 769px) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  display: none;
  @media (max-width: 769px) {
    display: flex;
  }
`;

const NuggetContainer = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 769px) {
    font-size: 0.8rem;
  }
`;



import React, { useEffect, useState } from "react";
import { Container, Header } from "semantic-ui-react";

import ActionMenu from './ActionMenu';

import SyllabusLink from "components/common/SyllabusLink";

const ACCEPT = "Accept Syllabus";
const REJECT = "Reject Syllabus";

export default function SyllabiList() {
    const [syllabi, setSyllabiList] = useState([]);
    const [syllabusCount, setSyllabusCount] = useState(0);

    const fetchSyllabi = () => {
        fetch(`/api/sillabi/get/pending`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                throw response;
            })
            .then(response => response.json())
            .then(json => {
                setSyllabiList(json.sillabi)
                setSyllabusCount(json.sillabi.length)
            })
            .catch(error => { return error })
    }

    const updateSyllabus = async (syllabusId, status) => {
        let newStatus = 'pending'
        if (status === ACCEPT) {
            newStatus = 'approved'
        } else if (status === REJECT) {
            newStatus = 'rejected'
        }

        const response = await fetch(`/api/sillabi/put/${syllabusId}/${newStatus}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" }
        });
        try {
            const result = await response.json();
            fetchSyllabi()

            return result
        } catch (err) {
            return { error: err };
        }
    };

    useEffect(() => {
        fetchSyllabi()
    }, [])
    return (
        <Container>
            <Header size="large">{syllabusCount} Syllabi </Header>
            <Container>
                <Header size="medium">Pending Syllabi:</Header>
                {syllabi && syllabi.map((syllabus) => (
                    <Container key={syllabus.syllabus_id}>
                        <SyllabusLink
                            style={{ marginBottom:"1rem" }}
                            syllabus={{
                                syllabusId: syllabus.syllabus_id,
                                profFirstName: syllabus.first_name,
                                profLastName: syllabus.last_name,
                                courseName: syllabus.course_name,
                                courseCode: syllabus.course_code
                            }}
                        />
                        <ActionMenu
                            handleSubmit={updateSyllabus}
                            itemId={syllabus.syllabus_id}
                            options={[ACCEPT, REJECT]}
                        />
                    </Container>
                ))}
            </Container>
        </Container>
    );
    
}

import PropTypes from "prop-types";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Header, Button, Modal, Icon } from "semantic-ui-react";
import styled from "styled-components";

import DFPAdSlot from "./common/DFPAdSlot";
import GetSyllabi from "./GetSyllabi";
import SubmitQuoteForm from "./SubmitQuoteForm";


import { CourseDisplayLink } from "components/common/CourseDisplay";
import CreateReviewButton from "components/common/CreateReviewButton";
import ErrorComponent from "components/common/ErrorComponent";
import LoadingComponent from "components/common/LoadingComponent";
import { ProfessorDisplayName } from "components/common/ProfessorDisplay";
import Quote from "components/common/Quote";
import useDataFetch from "components/common/useDataFetch";
import Ratings from "components/graphs/ratings";
import ReviewCard from "components/reviews/ReviewCard";
import ReviewSection from "components/reviews/ReviewSection";


const propTypesProfessorCourses = {
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      courseId: PropTypes.number.isRequired,
      courseName: PropTypes.string.isRequired,
      courseCode: PropTypes.string.isRequired,
    })
  ).isRequired,
};

function getRatingsHistogram(reviews) {
  const ratings = reviews.map((review) => {
    return review.rating;
  });

  const count = ratings.reduce((acc, rating) => {
    acc[rating] = (acc[rating] || 0) + 1;
    return acc;
  }, {});

  return count;
}

function ProfessorCourseList({ courses }) {
  return (
    <div className="add-margin">
      <Header className="no-margin">Courses: </Header>
      {courses.map(({ courseId, courseName, courseCode }, index) => (
        <div key={courseId}>
          <CourseDisplayLink
            courseCode={courseCode}
            courseId={courseId}
            courseName={courseName}
          />
          {index !== courses.length - 1 ? ", " : ""}
        </div>
      ))}
    </div>
  );
}

const reviewPropType = PropTypes.shape({
  reviewType: PropTypes.string.isRequired,
  reviewHeader: PropTypes.shape({
    courseId: PropTypes.number.isRequired,
    courseName: PropTypes.string.isRequired,
    courseCode: PropTypes.string.isRequired,
  }).isRequired,
  votes: PropTypes.shape({
    initUpvoteCount: PropTypes.number.isRequired,
    initDownvoteCount: PropTypes.number.isRequired,
    initFunnyCount: PropTypes.number.isRequired,
    upvoteClicked: PropTypes.bool.isRequired,
    downvoteClicked: PropTypes.bool.isRequired,
    funnyClicked: PropTypes.bool.isRequired,
  }).isRequired,
  workload: PropTypes.string,
  submissionDate: PropTypes.string.isRequired,
  reviewId: PropTypes.number.isRequired,
  deprecated: PropTypes.bool,
  content: PropTypes.string,
});

const propTypesProfessorSummary = {
  badges: PropTypes.arrayOf(PropTypes.number).isRequired,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      courseId: PropTypes.number.isRequired,
      courseName: PropTypes.string.isRequired,
      courseCode: PropTypes.string.isRequired,
      courseProfessorID: PropTypes.number.isRequired,
    })
  ).isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  professorId: PropTypes.number.isRequired,
  nugget: PropTypes.number.isRequired,
  professorReviewHighlight: PropTypes.arrayOf(reviewPropType).isRequired,
  ratingsHistogram: PropTypes.objectOf(PropTypes.int).isRequired,
};

export function ProfessorSummary({
  badges,
  courses,
  firstName,
  lastName,
  professorId,
  nugget,
  professorReviewHighlight,
  ratingsHistogram,
}) {
  return (
    <ProfessorCard>
      <Grid stackable columns={2}>
        <Grid.Column>
          <div>
            <ProfessorDisplayName
              as="header"
              badges={badges}
              firstName={firstName}
              lastName={lastName}
              nugget={nugget}
              size="huge"
            />
          </div>
          <ProfessorCourseList courses={courses} />
          <div>
            <CreateReviewButton
              relaxed
              center = {professorReviewHighlight.length === 0}
              color="orange"
              professorId={professorId}
              professorName={`${firstName} ${lastName}`}
              subject={`${firstName} ${lastName}`}
            />
          </div>
        </Grid.Column>
        {
          professorReviewHighlight.length > 0 
            &&
          <Grid.Column>
            <CenteredColumn>
              <Ratings ratingsHistogram={ratingsHistogram}/>
            </CenteredColumn>
          </Grid.Column>
        }
      </Grid>
      {professorReviewHighlight.length === 0 && <NoProfessorReviewBanner/>}
      <div>
        <GetSyllabi courseProfId={professorId} courses={courses} firstName={firstName} lastName={lastName}/>
      </div>
    </ProfessorCard>
  );
}

function NoProfessorReviewBanner() {
  return (
    <div style={{ textAlign: "center", padding: "2rem" }}>
      <Header>There are currently no reviews for this professor.</Header>
    </div>
  );
}

const propTypesReview = {
  review: reviewPropType.isRequired,
};

function ProfessorReviewCard({ review }) {
  return (
    <ReviewCard
      content={review.content}
      deprecated={review.deprecated}
      reviewHeader={review.reviewHeader}
      reviewId={review.reviewId}
      reviewType={review.reviewType}
      submissionDate={review.submissionDate}
      votes={review.votes}
      workload={review.workload}
    />
  );
}

function SingleProfessorReviewHighlight({ review }) {
  return (
    <Grid stackable columns={2}>
      <Grid.Column key="most_agreed_review_highlight">
        <Header>Most Agreed Review</Header>
        <ProfessorReviewCard review={review} />
      </Grid.Column>
    </Grid>
  );
}

const propTypesProfessorReviewHighlight = {
  professorReviewHighlight: PropTypes.arrayOf(reviewPropType).isRequired,
};

function DoubleProfessorReviewHighlight({ professorReviewHighlight }) {
  return (
    <Grid stackable columns={2}>
      <Grid.Column key="most_positive_review_highlight">
        <Header>Most Agreed Review</Header>
        <ProfessorReviewCard review={professorReviewHighlight[0]} />
      </Grid.Column>
      <Grid.Column key="most_negative_review_highlight">
        <Header>Most Controversial Review</Header>
        <ProfessorReviewCard review={professorReviewHighlight[1]} />
      </Grid.Column>
    </Grid>
  );
}


function ProfessorReviewHighlight({ professorReviewHighlight }) {
  /* 
    professorReviewHighlight can have lengths 0-2:
      2: professorReviewHighlight[0] is the most positive review and
          professorReviewHighlight[1] the most negative review
      1: Either most positive and negative reviews are the same review
          or there is only one review for the course
      0: There are no reviews for the course
  */

  if (professorReviewHighlight.length === 1) {
    return (
      <SingleProfessorReviewHighlight review={professorReviewHighlight[0]} />
    );
  }
  if (professorReviewHighlight.length === 2) {
    return (
      <DoubleProfessorReviewHighlight
        professorReviewHighlight={professorReviewHighlight}
      />
    );
  }
  return <></>;
}

export default function ProfessorInfoPage() {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 800);
  const [quoteModalOpen, setQuoteModalOpen] = useState(false);
  const [thankYouModalOpen, setThankYouModalOpen] = useState(false);
  const [quoteID, setQuoteID] = useState(null);

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 800);
  };
  window.addEventListener("resize", handleResize);
  const { professorId } = useParams();

  const professorDataFetched = useDataFetch(`/api/professor/${professorId}`, {
    professorSummary: {
      firstName: "",
      lastName: "",
      badges: [],
      courses: [],
      nugget: "",
    },
    professorReviewHighlight: [],
  });

  const reviewDataFetched = useDataFetch(
    `/api/review/get/professor/${professorId}`,
    {
      reviews: [],
    }
  );

  const quotesDataFetched = useDataFetch(
    `/api/professor/${professorId}/quotes`,
    {
      quotes: [],
    }
  );

  const {
    professorSummary,
    professorReviewHighlight,
  } = professorDataFetched.data;
  const isProfessorLoading = professorDataFetched.isLoading;
  const isProfessorError = professorDataFetched.isError;

  const { reviews } = reviewDataFetched.data;
  const { quotes } = quotesDataFetched.data;
  const isReviewLoading = reviewDataFetched.isLoading;
  const isQuotesLoading = quotesDataFetched.isLoading;
  const isReviewError = reviewDataFetched.isError;
  const isQuotesError = quotesDataFetched.isError;
  const ratingsHistogram = getRatingsHistogram(reviews)


  if (isProfessorLoading || isProfessorError) {
    return isProfessorLoading ? <LoadingComponent /> : <ErrorComponent />;
  }

  if (isReviewLoading || isReviewError) {
    return isReviewLoading ? <LoadingComponent /> : <ErrorComponent />;
  }

  if (isQuotesLoading || isQuotesError) {
    return isQuotesLoading ? <LoadingComponent /> : <ErrorComponent />;
  }

  const randomQuoteIndex = Math.floor(Math.random() * quotes.length);
  const randQuote = quotes[randomQuoteIndex];

  const handleCloseQuoteModal = (response) => {
    setQuoteID(response.quote_id); // Update quoteID state with the value from response
    setQuoteModalOpen(false);
  };

  const handleCloseThankYouModal = () => {
    setThankYouModalOpen(false);
  };

  const handleOpenThankYouModal = () => {
    setThankYouModalOpen(true);
  };

  
  return (
    <>
      <ProfessorSummary
        badges={professorSummary.badges}
        courses={professorSummary.courses}
        firstName={professorSummary.firstName}
        lastName={professorSummary.lastName}
        nugget={professorSummary.nugget}
        professorId={Number(professorId)}
        professorReviewHighlight={professorReviewHighlight}
        Quote={Quote}
        ratingsHistogram={ratingsHistogram}
      />
      <ProfessorReviewHighlight
        professorReviewHighlight={professorReviewHighlight}
      />
      <Button style={{marginTop: "1rem"}} onClick={() => setQuoteModalOpen(true)}>Submit Quote/Impression</Button>

      <Modal
        className="submit-quote"
        open={quoteModalOpen}
        onClose={() => setQuoteModalOpen(false)}
        onOpen={() => setQuoteModalOpen(true)}
      >
        <Modal.Header>
          Submit a quote/impression
          <Button className="quote" onClick={() => setQuoteModalOpen(false)}><Icon name = 'cancel'/></Button>
        </Modal.Header>
        
        <Modal.Content>
          <SubmitQuoteForm 
            courses={professorSummary.courses} 
            firstName={professorSummary.firstName} 
            lastName={professorSummary.lastName}
            onCloseModal={handleCloseQuoteModal}
            onOpenThankYouModal={handleOpenThankYouModal}
          />
        </Modal.Content>
      </Modal>

      {/* Thank You Modal */}
      <Modal
        className="thank-you-modal"
        open={thankYouModalOpen}
        onClose={() => setThankYouModalOpen(false)}
      >
        <Modal.Header>Thank You!</Modal.Header>
        <Modal.Content>
          <p>Please save your <span style={{color: "#FDC636"}}>Quote ID: {quoteID} </span> in case you need to refer to your submission in the future.</p>
        </Modal.Content>
        <Button className="closeQuote" onClick={handleCloseThankYouModal}>Return to previous page</Button>
      </Modal>
      
      {randQuote ?
        (<Quote
          content={randQuote.content}
          courseCode={randQuote.courseCode}
          courseName={randQuote.courseName}
          firstName={randQuote.firstName}
          lastName={randQuote.lastName}
          quoteDate={randQuote.quoteDate}
          quoteId={randQuote.quoteId}
        />):
        (<></>)
      }
      <AddDiv>
        {isLargeScreen ? (
          <>
            <DFPAdSlot height={90} path="culpa_leaderboard" width={728} />
          </>
        ) : (
          <>
            <DFPAdSlot height={250} path="culpa_leaderboard" width={320} />
          </>
        )}
      </AddDiv>
      {reviews.length > 0 && (
        <ReviewSection
          associatedEntities={professorSummary.courses}
          id={Number(professorId)}
          initReviews={reviews}
          pageType="professor"
        />
      )}
    </>
  );
}

ProfessorCourseList.propTypes = propTypesProfessorCourses;

ProfessorSummary.propTypes = propTypesProfessorSummary;

ProfessorReviewCard.propTypes = propTypesReview;

SingleProfessorReviewHighlight.propTypes = propTypesReview;

DoubleProfessorReviewHighlight.propTypes = propTypesProfessorReviewHighlight;

ProfessorReviewHighlight.propTypes = propTypesProfessorReviewHighlight;

const AddDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
`

const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`


const ProfessorCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  word-wrap: break-word;
  margin-bottom: 2rem;
`
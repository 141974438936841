import PropTypes from "prop-types";
import React from "react";
import { Header, List, Grid} from "semantic-ui-react";
import styled from 'styled-components';

import { DepartmentDisplayLink } from "components/common/DepartmentDisplay";
import ErrorComponent from "components/common/ErrorComponent";
import LoadingComponent from "components/common/LoadingComponent";
import useDataFetch from "components/common/useDataFetch";

const defaultProps = {
  departments: [],
};

const propTypes = {
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      departmentsList: PropTypes.arrayOf(
        PropTypes.shape({
          departmentId: PropTypes.number.isRequired,
          departmentName: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      letter: PropTypes.string.isRequired
    }).isRequired
  ),
};

export function Departments({ departments }) {
  return (
    <>
      <Header size="huge">List of Departments</Header>
      <List>
        <Grid stackable columns={2}>
          <Grid.Column>
            {departments.slice(0, Math.ceil(departments.length / 2)).map(({ letter, departmentsList }) => (
              <DepartmentGroup departmentsList={departmentsList} letter={letter} />
            ))}
          </Grid.Column>
          <Grid.Column>
            {departments.slice(Math.ceil(departments.length / 2), departments.length).map(({ letter, departmentsList }) => (
              <DepartmentGroup departmentsList={departmentsList} letter={letter} />
            ))}
          </Grid.Column>
        </Grid>
      </List>
    </>
  );
}

const DepartmentGroupPropTypes = {
  departmentsList: PropTypes.arrayOf(
    PropTypes.shape({
      departmentId: PropTypes.number.isRequired,
      departmentName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  letter: PropTypes.string.isRequired
};

const DepartmentGroup = ({ letter, departmentsList }) => {
  return (
    <DepartmentGroupContainer>
      <Letter>
        <Header size="huge">{letter}</Header>
      </Letter>
      <Grid.Column>
        {departmentsList.map(({ departmentId, departmentName }) => (
          <List.Item className="list-item" key={departmentId}>
            <DepartmentDisplayLink
              departmentId={departmentId}
              departmentName={departmentName}
            />
          </List.Item>
        ))}
      </Grid.Column>
    </DepartmentGroupContainer>
  )
}

export default function DepartmentsPage() {
  const {
    data: { departments },
    isLoading,
    isError,
  } = useDataFetch("/api/department/all", {
    departments: [],
  });

  if (isLoading || isError) {
    return isLoading ? <LoadingComponent /> : <ErrorComponent />;
  }

  return <Departments departments={departments} />;
}

DepartmentGroup.propTypes = DepartmentGroupPropTypes;
Departments.propTypes = propTypes;
Departments.defaultProps = defaultProps;

const Letter = styled.div`
  margin: 0 1rem;
`

const DepartmentGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid black;
`

import React from "react";
import { Link } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";

export default function AboutUsPage() {

  return (
    <>
      <Header size="huge">About Us</Header>
      <Container>
        <Header size="large">About</Header>
        <Header className="culpa-description">
          CULPA (Columbia Underground Listing of Professor Ability), founded in 1997, is a public platform for students at Columbia University to anonymously review courses and professors. Our mission is to provide utility to students by facilitating honest, unfiltered opinions and discussions on the academic experience. The platform is moderated by current students to consistently deliver up-to-date information on courses and professors. As of November 15, 2022, CULPA is a service of the Spectator Publishing Company operated by Spectator&apos;s Product and Engineering teams.
        </Header>
        <Header className="culpa-description">
          CULPA began distributing information in 1997, and each item of user-generated content has been continuously available since the date it was first distributed.
        </Header>
        <Header size="large">How to use</Header>
        <Header className="culpa-description">
          Search by professor or course to find reviews that fellow students have written about their experiences in class. You may also visit our departments page to explore professors and classes in and outside of your major. To submit a review, click the “Write A Review” button in the upper right. Your review will be reviewed and published shortly. Want to be part of CULPA? Visit the <Link to='/join-the-team'>Join the Team</Link> page to learn more.
        </Header>
        <Header size="large">Contact Us</Header>
        <br />
        <Header className="culpa-description">
          You can reach us at <a href="mailto:admin@culpa.info">admin@culpa.info</a>.
        </Header>
      </Container>
    </>
  );
}

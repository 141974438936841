import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Message, Icon } from "semantic-ui-react";
import styled from "styled-components";

import announcements from "data/announcements";

export default function Banner(){
    const [visible, setVisible] = useState(true);
    const [isRecent, setIsRecent] = useState(false);

    useEffect(() => {
        const parsedDate = Date.parse(announcements[0].date);
        const twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

        setIsRecent(parsedDate > twoWeeksAgo);
    }, []);    

    return(
        <>
            {isRecent && visible &&
                <Message 
                size="big" 
                style = {{ backgroundColor: "#DBF2FF", boxShadow:"none" }} >
                <Bold>Announcement!</Bold> Some updates on CULPA from the team. Click <Bold> 
                    <Link to='/announcements'>here</Link>.</Bold> 
                    <Icon className="close icon" onClick={() => setVisible(false)}/>
            </Message> }
        </>
    )
}

const Bold = styled.span`
  font-weight: 700;
`
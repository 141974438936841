/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Dropdown, Button, Input, Form, FormField, Grid } from "semantic-ui-react";
import styled from "styled-components";

import syllabusUploaded from "../icons/syllabus-submission.png";
import uploadSyllabus from "../icons/upload-syllabus.png";

async function submitSyllabusToServer(formData) {
    try {
        const response = await fetch("/api/sillabi/submit", {
        method: "POST",
        body: formData
        });

        if (!response.ok) {
            throw new Error('Network response was not ok.');
          }

        return await response.json(); // Return the parsed JSON data
      } 
    catch (err) {
        return err;
    }
}

  
// Define propTypes for SubmitQuoteForm component
const propTypesSubmitSyllabusForm = {
    courses: PropTypes.arrayOf(
        PropTypes.shape({
        courseId: PropTypes.number.isRequired,
        courseName: PropTypes.string.isRequired,
        courseCode: PropTypes.string.isRequired,
        courseProfessorId: PropTypes.number.isRequired,
        })
    ).isRequired, // Add isRequired to ensure the prop is provided
    professors: PropTypes.arrayOf(
        PropTypes.shape({
            professorID: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            courseProfessorId: PropTypes.number.isRequired,
        })
    ).isRequired,
    courseCode: PropTypes.number.isRequired,
    courseName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired, // Add prop type validation for firstName
    isProfessor: PropTypes.bool.isRequired,
    lastName: PropTypes.string.isRequired, // Add prop type validation for lastName
    onCloseModal: PropTypes.func.isRequired,
    onOpenThankYouModal: PropTypes.func.isRequired
};

export default function SubmitSyllabusForm ({ courses, firstName, lastName, courseName, professors, courseCode, isProfessor, onCloseModal, onOpenThankYouModal }) {
    const [syllabus, setSyllabus] = useState({
      course_professor_id: '',
      file: null
    });
    const [errors, setErrors] = useState({});
    const [backgroundImage, setBackgroundImage] = useState(`url(${uploadSyllabus})`);
    const [string,setFileNameString] = useState("");

    // const [quoteID, setQuoteID] = useState('');
  
    const dropdownOptions = isProfessor ? (
        courses.map(course => ({
            key: course.courseId,
            value: course.courseProfessorId,
            text: `${course.courseCode} - ${course.courseName}`
        }))
    ) : (
        professors.map(professor => ({
            key: professor.professorID,
            value: professor.courseProfessorId,
            text: `${professor.firstName} ${professor.lastName}`
        }))
    );
  
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSyllabus(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
  
    const handleDropdownChange = (event, data) => {
        setSyllabus(prevState => ({
            ...prevState,
            course_professor_id: data.value 
        }));
    };

    const handleFileChange = (event) => {
        const fileAdd = event.target.files[0];
        if (fileAdd) {
            setBackgroundImage(`url(${syllabusUploaded})`);
            setFileNameString(fileAdd.name);
            setSyllabus(prevState => ({
                ...prevState,
                file: fileAdd
            }));
        }
        if (fileAdd instanceof File) { // Check if the file is an instance of File
            setSyllabus(prevState => ({
                ...prevState,
                file: fileAdd
            }));
        }
    }

    const onSubmitSyllabi = async (event) => {
        event.preventDefault();

        const newErrors = {};

        // Check if a professor or course is selected
        if (!syllabus.course_professor_id) {
            newErrors.dropdown = isProfessor ? 'Please select a professor before submitting.' : 'Please select a course before submitting.';
        }

        if (!syllabus.file) {
            newErrors.file = 'Please provide a quote or impression before submitting.';
          }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const formData = new FormData();
        formData.append('course_professor_id', syllabus.course_professor_id);
        formData.append('file', syllabus.file);
        
        // formData.forEach((value, key) => {
        //   console.log(key, value);
        // });

        const response = await submitSyllabusToServer(formData);
        onOpenThankYouModal();
        onCloseModal(response);
    };
  
  
    return (
      <>
        <Form onSubmit={onSubmitSyllabi}>
            <Grid stackable columns={2}>
                <Grid.Column>
                {isProfessor ? (
                    <>
                        <FormField>
                            <label htmlFor="professor-name">Professor:</label>
                            <Input
                                id="professor-name"
                                name="professor name"
                                placeholder={`${firstName} ${lastName}`}
                                type="text"
                                value={`${firstName} ${lastName}`}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField error={errors.dropdown}>
                            <label htmlFor="dropdown">Course:</label>
                            <Dropdown
                                fluid
                                selection
                                id="dropdown"
                                options={dropdownOptions}
                                placeholder='Choose Below'
                                rules={{ required: "Please select a matching course" }}
                                onChange={handleDropdownChange}
                            />
                        </FormField>
                    </>
                ) : (
                    <>
                        <FormField>
                            <label htmlFor="course-name">Course:</label>
                            <Input
                                id="course-name"
                                name="course name"
                                placeholder={`${courseCode} - ${courseName}`}
                                type="text"
                                value={`${courseCode} - ${courseName}`}
                                onChange={handleInputChange}
                            />
                        </FormField>
                        <FormField error={errors.dropdown}>
                            <label htmlFor="dropdown">Professor:</label>
                            <Dropdown
                                fluid
                                selection
                                id="dropdown"
                                options={dropdownOptions}
                                placeholder='Choose Below'
                                rules={{ required: "Please select a matching Professor" }}
                                onChange={handleDropdownChange}
                            />
                        </FormField>
                    </>
                )}
                </Grid.Column>
                <Grid.Column>
                    <PDFWrapper>
                        <FormField error={errors.file} style={{width: "fit-content", margin: "auto"}}>
                            <InputContainer backgroundImage={backgroundImage} hasError={!!errors.file} role="button" tabIndex="0">
                                <SyllabusInput
                                    accept = ".pdf"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                            </InputContainer>
                            <PDFString>
                                {string}
                            </PDFString>
                        </FormField>
                        <Button className="submitSyllabus" name="submit" type="submit" >
                            SUBMIT SYLLABUS
                        </Button>
                    </PDFWrapper>
                </Grid.Column>
          </Grid>
        </Form>
      </>
    );
  }

const SyllabusInput = styled.input`
    opacity: 0.0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
`;

const InputContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    background-image: ${props => props.backgroundImage};
    background-size: cover;
    cursor: pointer;
    border: ${props => props.hasError ? '2px solid #c9483f' : 'none'};
    border-radius: ${props => props.hasError ? '1.5rem' : 'none'};
`;

const PDFString = styled.div`
    font-size: 1rem;
    color: #4071CA;
    width: 120px;
    word-break: break-all;
    text-align: center;
    cursor: pointer;
`;

const PDFWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensure it takes up full height */
`;

SubmitSyllabusForm.propTypes = propTypesSubmitSyllabusForm;
import PropTypes from "prop-types";
import React from "react";
import { Header, Grid, Container, Divider } from "semantic-ui-react";

import announcements from "data/announcements";

export default function AnnouncementsPage() {

  return (
    <>
      <Header size="huge">Announcements</Header>
      <Divider />
      {announcements.map(({ title, date, message }) => (
        <AnnouncementCard content={message} submissionDate={date} title={title} />
      ))}
    </>
  );
}

const propTypesAnnouncementCard = {
  content: PropTypes.string,
  submissionDate: PropTypes.string.isRequired,
  title: PropTypes.string,
};

const defaultPropsAnnouncementCard = {
  title: "",
  content: "",
};

function AnnouncementCard({
  content,
  submissionDate,
  title,
}) {
  return (
    <Grid className="add-margin fluid">
      <Grid.Column className="review-card-left-column">
        <Header className="no-margin review-card-header" size="medium">{title}</Header>
        <Container>
          <Header size="small">{submissionDate}</Header>
          <div>
            {content.map((paragraph) => (
              <div>
                <p dangerouslySetInnerHTML={{ __html:paragraph}}/>
                <br/>
              </div>
            ))}
            <p>Best,</p>
            <p>The CULPA Team</p>
          </div>
        </Container>
      </Grid.Column>
    </Grid>
  );
}

AnnouncementCard.propTypes = propTypesAnnouncementCard;
AnnouncementCard.defaultProps = defaultPropsAnnouncementCard;

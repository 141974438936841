import PropTypes from "prop-types";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Header, Icon, Accordion, Grid } from "semantic-ui-react";

import CreateReviewButton from "./common/CreateReviewButton";
import GetSyllabi from "./GetSyllabi";

import { CourseDisplayName } from "components/common/CourseDisplay";
import { DepartmentDisplayLink } from "components/common/DepartmentDisplay";
import ErrorComponent from "components/common/ErrorComponent";
import LoadingComponent from "components/common/LoadingComponent";
import { ProfessorDisplayLink } from "components/common/ProfessorDisplay";
import useDataFetch from "components/common/useDataFetch";
import ReviewCard from "components/reviews/ReviewCard";
import ReviewSection from "components/reviews/ReviewSection";


const MAX_NUM_PROFESSORS_IN_LIST = 5;

const propTypesCourseProfessors = {
  courseProfessors: PropTypes.arrayOf(
    PropTypes.shape({
      badges: PropTypes.arrayOf(PropTypes.number).isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      professorId: PropTypes.number.isRequired,
      nugget: PropTypes.number.isRequired,
      professorDepartments: PropTypes.arrayOf(
        PropTypes.shape({
          professorDepartmentId: PropTypes.number.isRequired,
          professorDepartmentName: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    })
  ).isRequired,
};

function ProfessorsList({ courseProfessors }) {
  return (
    <div className="add-margin">
      <Header className="no-margin">Professors: </Header>
      {courseProfessors.map(
        ({ badges, firstName, lastName, professorId, nugget }, index) => {
          return (
            <span key={professorId}>
              <ProfessorDisplayLink
                badges={badges}
                firstName={firstName}
                lastName={lastName}
                nugget={nugget}
                professorId={professorId}
              />
              {index !== courseProfessors.length - 1 ? ", " : ""}
            </span>
          );
        }
      )}
    </div>
  );
}

const propTypesProfessorsAccordion = {
  isAccordionActive: PropTypes.bool.isRequired,
  setAccordionActive: PropTypes.func.isRequired,
  courseProfessors: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      professorId: PropTypes.number.isRequired,
      badges: PropTypes.arrayOf(PropTypes.number).isRequired,
      nugget: PropTypes.number.isRequired,
    })
  ).isRequired,
};

function ProfessorsAccordion({
  isAccordionActive,
  setAccordionActive,
  courseProfessors,
}) {
  return (
    <Accordion className="add-margin">
      <Accordion.Title
        active={isAccordionActive}
        onClick={() => setAccordionActive(!isAccordionActive)}
      >
        <Icon name={!isAccordionActive ? "angle down" : "angle up"} />
        <Header> {!isAccordionActive ? "Show" : "Hide"} all professors who teach this course </Header>
          
      </Accordion.Title>
      <Accordion.Content active={isAccordionActive}>
        <CourseProfessorsGrid courseProfessors={courseProfessors} />
      </Accordion.Content>
    </Accordion>
  );
}

function ProfessorsComponent({
  isAccordionActive,
  setAccordionActive,
  courseProfessors,
}) {
  return courseProfessors.length > MAX_NUM_PROFESSORS_IN_LIST ? (
    <ProfessorsAccordion
      courseProfessors={courseProfessors}
      isAccordionActive={isAccordionActive}
      setAccordionActive={setAccordionActive}
    />
  ) : (
    <ProfessorsList courseProfessors={courseProfessors} />
  );
}

function CourseProfessorsGrid({ courseProfessors }) {
  const cutoffIndex = Math.ceil(courseProfessors.length/3);
  return (
    <Grid stackable columns={3}>
      <Grid.Column>
      {courseProfessors.slice(0, cutoffIndex).map(
        ({
          badges,
          firstName,
          lastName,
          professorId,
          nugget
        }) => {
          return (
            <Grid.Row key={`${professorId}_row`}>
              <Grid.Column key={`${professorId}_link`}>
                <ProfessorDisplayLink
                  badges={badges}
                  firstName={firstName}
                  lastName={lastName}
                  nugget={nugget}
                  professorId={professorId}
                />
              </Grid.Column>
            </Grid.Row>
          );
        }
      )}
      </Grid.Column>
      <Grid.Column>
      {courseProfessors.slice(cutoffIndex, cutoffIndex*2).map(
        ({
          badges,
          firstName,
          lastName,
          professorId,
          nugget
        }) => {
          return (
            <Grid.Row key={`${professorId}_row`}>
              <Grid.Column key={`${professorId}_link`}>
                <ProfessorDisplayLink
                  badges={badges}
                  firstName={firstName}
                  lastName={lastName}
                  nugget={nugget}
                  professorId={professorId}
                />
              </Grid.Column>
            </Grid.Row>
          );
        }
      )}
      </Grid.Column>
      <Grid.Column>
      {courseProfessors.slice(cutoffIndex*2, courseProfessors.length).map(
        ({
          badges,
          firstName,
          lastName,
          professorId,
          nugget
        }) => {
          return (
            <Grid.Row key={`${professorId}_row`}>
              <Grid.Column key={`${professorId}_link`}>
                <ProfessorDisplayLink
                  badges={badges}
                  firstName={firstName}
                  lastName={lastName}
                  nugget={nugget}
                  professorId={professorId}
                />
              </Grid.Column>
            </Grid.Row>
          );
        }
      )}
      </Grid.Column>
    </Grid>
  );
}

const propTypesCourseInfo = {
  courseName: PropTypes.string.isRequired,
  courseID: PropTypes.number.isRequired,
  courseCode: PropTypes.string.isRequired,
  departmentId: PropTypes.number.isRequired,
  departmentName: PropTypes.string.isRequired,
  courseProfessors: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      professorId: PropTypes.number.isRequired,
      nugget: PropTypes.number.isRequired,
      professorDepartments: PropTypes.arrayOf(
        PropTypes.shape({
          professorDepartmentId: PropTypes.number.isRequired,
          professorDepartmentName: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    })
  ).isRequired,
};

export function CourseInfo({
  courseName,
  courseID,
  courseCode,
  departmentId,
  departmentName,
  courseProfessors,
}) {
  const [isAccordionActive, setAccordionActive] = useState(false);
  return (
    /* use div to prevent margin collapsing */
    <>
      <div>
        <CourseDisplayName
          as="header"
          courseCode={courseCode}
          courseName={courseName}
          size="huge"
        />
      </div>
      <div>
        <Header>Department: </Header>
        <DepartmentDisplayLink
          departmentId={departmentId}
          departmentName={departmentName}
        />
      </div>
      
      
      <div>
        <ProfessorsComponent
          courseProfessors={courseProfessors}
          isAccordionActive={isAccordionActive}
          setAccordionActive={setAccordionActive}
        />
      </div>

      <div>
        <GetSyllabi 
          courseCode={courseCode}
          courseName={courseName}
          courseProfessors={courseProfessors}
          courseProfId={courseID} 
          isProfessor={false}
        />
      </div>
      
    </>
  );
}

const reviewPropType = PropTypes.shape({
  reviewType: PropTypes.string.isRequired,
  reviewHeader: PropTypes.shape({
    profId: PropTypes.number.isRequired,
    profFirstName: PropTypes.string.isRequired,
    profLastName: PropTypes.string.isRequired,
    badges: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  votes: PropTypes.shape({
    initUpvoteCount: PropTypes.number.isRequired,
    initDownvoteCount: PropTypes.number.isRequired,
    initFunnyCount: PropTypes.number.isRequired,
    upvoteClicked: PropTypes.bool.isRequired,
    downvoteClicked: PropTypes.bool.isRequired,
    funnyClicked: PropTypes.bool.isRequired,
  }).isRequired,
  workload: PropTypes.string,
  submissionDate: PropTypes.string.isRequired,
  reviewId: PropTypes.number.isRequired,
  deprecated: PropTypes.bool,
  content: PropTypes.string,
});

const propTypesCourseReviewCard = {
  review: reviewPropType.isRequired,
};

function CourseReviewCard({ review }) {
  const {
    reviewType,
    reviewHeader,
    votes,
    workload,
    submissionDate,
    reviewId,
    deprecated,
    content,
  } = review;

  return (
    <ReviewCard
      content={content}
      deprecated={deprecated}
      reviewHeader={reviewHeader}
      reviewId={reviewId}
      reviewType={reviewType}
      submissionDate={submissionDate}
      votes={votes}
      workload={workload}
    />
  );
}

const propTypesDoubleCourseReviewHighlight = {
  courseReviewHighlight: PropTypes.arrayOf(reviewPropType).isRequired,
};

function DoubleCourseReviewHighlight({ courseReviewHighlight }) {
  return (
    <>
      <Grid stackable columns={2}>
        <Grid.Column>
          <Header>Most Positive Review</Header>
          <CourseReviewCard review={courseReviewHighlight[0]} />
        </Grid.Column>
        <Grid.Column>
          <Header>Most Negative Review</Header>
          <CourseReviewCard review={courseReviewHighlight[1]} />
        </Grid.Column>
      </Grid>
    </>
  );
}

const propTypesSingleCourseReviewHighlight = {
  courseReviewHighlight: PropTypes.arrayOf(reviewPropType).isRequired,
};

function SingleCourseReviewHighlight({ courseReviewHighlight }) {
  return (
    <>
      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Header>Most Agreed Review</Header>
            <CourseReviewCard review={courseReviewHighlight[0]} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

const propTypesCourseReviewHighlight = {
  courseReviewHighlight: PropTypes.arrayOf(reviewPropType),
};

const defaultPropsCourseReviewHighlight = {
  courseReviewHighlight: [],
};

function CourseReviewHighlight({ courseReviewHighlight }) {
  /* 
      NOTE: courseReviewHighlight[0] is the most positive and
            [1] the most negative (when the length is 2)

      courseReviewHighlight can have lengths 0-2:
        2: There are most positive and negative reviews
        1: Either most positive and negative reviews are the same review
           or there is only one review for the course
        0: There are no reviews for the course
  */

  if (courseReviewHighlight.length === 2) {
    return (
      <DoubleCourseReviewHighlight
        courseReviewHighlight={courseReviewHighlight}
      />
    );
  }
  if (courseReviewHighlight.length === 1) {
    return (
      <SingleCourseReviewHighlight
        courseReviewHighlight={courseReviewHighlight}
      />
    );
  }

  return null;
}

export default function CourseInfoPage() {
  const { courseId } = useParams();

  const courseDataFetched = useDataFetch(`/api/course/${courseId}`, {
    courseSummary: {
      courseName: "",
      courseCode: "",
      departmentId: 0,
      departmentName: "",
      courseProfessors: [],
    },
    courseReviewHighlight: [],
  });

  const { courseSummary, courseReviewHighlight } = courseDataFetched.data;
  const isCourseLoading = courseDataFetched.isLoading;
  const isCourseError = courseDataFetched.isError;

  const reviewDataFetched = useDataFetch(`/api/review/get/course/${courseId}`, {
    reviews: [],
  });

  const { reviews } = reviewDataFetched.data;
  const isReviewLoading = reviewDataFetched.isLoading;
  const isReviewError = reviewDataFetched.isError;

  if (isCourseLoading || isCourseError) {
    return isCourseLoading ? <LoadingComponent /> : <ErrorComponent />;
  }

  if (isReviewLoading || isReviewError) {
    return isReviewLoading ? <LoadingComponent /> : <ErrorComponent />;
  }
  return (
    <>
      <CourseInfo
        courseCode={courseSummary.courseCode}
        courseID={courseId}
        courseName={courseSummary.courseName}
        courseProfessors={courseSummary.courseProfessors}
        departmentId={courseSummary.departmentId}
        departmentName={courseSummary.departmentName}
      />
      {courseReviewHighlight.length === 0 &&  <NoCourseReviewBanner/>}
      {courseReviewHighlight.length > 0 ? <ReviewSection
        associatedEntities={courseSummary.courseProfessors}
        id={Number(courseId)}
        initReviews={reviews}
        pageType="course"
      /> 
      : 
      <CreateReviewButton
          relaxed
          center = { courseReviewHighlight.length === 0 }
          color="orange"
          subject = { courseSummary.courseName }
        />
        
    }
    </>
  );
}

function NoCourseReviewBanner() {
  return (
    <div style = {{ textAlign: 'center', padding: '2rem' }}>
      <Header color="black">There are currently no reviews for this course.</Header>
    </div>
  );
}

ProfessorsList.propTypes = propTypesCourseProfessors;

ProfessorsAccordion.propTypes = propTypesProfessorsAccordion;

ProfessorsComponent.propTypes = propTypesProfessorsAccordion;

CourseProfessorsGrid.propTypes = propTypesCourseProfessors;

CourseInfo.propTypes = propTypesCourseInfo;

CourseReviewCard.propTypes = propTypesCourseReviewCard;

DoubleCourseReviewHighlight.propTypes = propTypesDoubleCourseReviewHighlight;

SingleCourseReviewHighlight.propTypes = propTypesSingleCourseReviewHighlight;

CourseReviewHighlight.propTypes = propTypesCourseReviewHighlight;

CourseReviewHighlight.defaultProps = defaultPropsCourseReviewHighlight;
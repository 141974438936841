import React from "react";
import { Container, Header } from "semantic-ui-react";

export default function JoinTheTeamPage() {

  return (
    <>
      <Header size="huge">Join the Team</Header>
      <Container>
        <Header className="culpa-description">
          CULPA is an entirely student-run website owned by the Spectator Publishing Company and operated by the Product and Engineering teams at Spectator. Have ideas about improving CULPA? Interested in web-development or database work? Want to become a moderator? See below on how to join!
        </Header>
        <Header size="medium">Administrator</Header>
        <Header className="culpa-description">
        CULPA administrators run the week-to-week operations of CULPA, review its governing policies, handle disputes, and are responsible for the public-facing communications/marketing of the service. Interested in becoming a CULPA Admin? <a href="http://www.specpublishing.com/4-apply-now">Join the Product team at Spectator</a>. Applications open at the beginning of each semester.
        </Header>
        <Header size="Medium">Designer</Header>
        <Header className="culpa-description">
        Our design team is responsible for improving CULPA as a platform. Designers create the UI/UX interface of the website, ideate and implement new high-impact features, and conduct market-research to make decisions about the future direction of CULPA. Our design team works in Figma. Interested in becoming a designer for CULPA? <a href="http://www.specpublishing.com/4-apply-now">Join the Product team at Spectator</a>. Applications open at the beginning of each semester.
        </Header>
        <Header size="Medium">Developer</Header>
        <Header className="culpa-description">
        CULPA developers maintain CULPA’s codebase, implement new features, and support the technical stack of the site. Interested in becoming a developer for CULPA? <a href="http://www.specpublishing.com/4-apply-now">Join the Engineering team at Spectator</a>. Applications open at the beginning of each semester.
        </Header>
        <Header size="Medium">Moderator</Header>
        <Header className="culpa-description">
        CULPA moderators read, review, and publish student reviews on a weekly basis in accordance with our moderation policies. CULPA operates an independent team with its own application process to conduct moderation. We are currently not looking for new moderators. If you are interested in helping us moderate incoming reviews, check back early Spring for information on how to apply!
        </Header>

        <Header className="culpa-description" size="medium">
          For more information about the Product and Engineering teams at Spectator, please visit <a href="https://www.specpublishing.com/about-businessinnovations">https://www.specpublishing.com/about-businessinnovations</a>.
        </Header>
      </Container>
    </>
  );
}

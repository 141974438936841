const announcements = [
    {
        title: "CULPA Updates",
        date: "April 16, 2023",
        message: ["Dear CULPA users,",
        "As our community continues to grow, we have been working hard to perfect CULPA to best serve you all! So what has changed? Well, we'd like to announce that we resolved many database issues since our relaunch in 2022 to improve the search experience. We will continue to refine the database to produce the best user experience.",
        "In addition, we updated the homepage to display the most recent reviews. Check out your peers' <a href = 'https://culpa.info/#/newestReviews'>new reviews</a> for their experiences with all the classes offered at Columbia! We also plan to bring new features to CULPA, such as professor quotes and syllabus submissions. We believe these features will further help inform students' decision-making process when planning their classes. As we continue to work on improving the site, we'd appreciate it if you let us know about any bugs or inconsistencies <a href='https://docs.google.com/forms/d/e/1FAIpQLSeAx0ZSdPR483Ap76AJndq3DYj-AaZmTFAzn57rhjQMHDrieg/viewform' target='_blank'>here</a>. We hope you're enjoying your experience with CULPA so far!"],
    },
    {
        title: "A Brief Update: We're back!",
        date: "November 14, 2022",
        message: ["Dear CULPA users,",
        "Certainly, many in the community noticed when CULPA went down in the fall of 2021. What happened to CULPA? Why has it been gone for so long? What about the many other CULPA's that have popped up in the original CULPA's absence? Today, we're happy to announce that CULPA is officially back in full capacity! We'd like to provide some transparency on where CULPA has been, why it looks different, and what you can expect from CULPA going forward.", 
        "CULPA is a website that has been part of Columbia tradition since its inception in 1997. There's a fun article on <a href='https://www.wikicu.com/Columbia_Underground_Listing_of_Professor_Ability' target='_blank'>wikicu</a> that summarizes CULPA's past up to 2008. By 2020, though, many of the technologies that CULPA was dependent on had become outdated. The last official CULPA team redesigned and reengineered the site from scratch in 2020 with the aim of ensuring that CULPA would be technologically sustainable for the foreseeable future.", 
        "The version of CULPA that you see now was designed and developed by that last official student team working on CULPA, consisting of Jonathan Zhang, Sungbin Kim, Elaine Wang, Jin Woo Won, Dorrie Tang, Pelin Cetin, Laura Block, Tamanna Hussain, and Kathy Lau. Enormous progress was made, though many of the team members graduated before the project could be completed. In the fall of 2021, the old CULPA fell into disrepair without an active undergraduate development team to complete the new version. As a temporary solution, a previous CULPA admin worked with Jonathan and crew to launch culpa.app as a temporary service to display existing reviews and collect new review submissions as the CULPA team sought a new home for CULPA at Columbia.", 
        "In the fall of 2022, CULPA entered into an agreement with the Spectator Publishing Company to transfer the ownership of CULPA as an organization to Spectator. And that brings us to the present! Throughout this semester, our teams have worked alongside alumni from the last official student CULPA team to complete development on the site and fulfill the original vision of the redesign. Moving forward, CULPA will functionally be operated as a subsidiary by the Product and Engineering teams at Spectator with an independent student team for moderation.", 
        "If you submitted reviews to culpa.app, worry not! We've gotten hold of your reviews and are working on publishing them in the coming weeks—we now also have an active moderation team that will review submissions on a weekly basis. Additionally, besides the new look and feel of the site, you may notice some quality of life updates including review sorting, the addition of the bronze nugget, and a cleaner database (far fewer duplicate classes and professors!). But we recognize that there's still a lot of work to be done. If you notice any bugs or inconsistencies with the site, we'd appreciate it if you let us know about them <a href='https://docs.google.com/forms/d/e/1FAIpQLSeAx0ZSdPR483Ap76AJndq3DYj-AaZmTFAzn57rhjQMHDrieg/viewform' target='_blank'>here</a>. We hope that you are as thrilled as we are about CULPA's return!"],
    }
    
]

export default announcements;
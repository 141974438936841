import React from "react";
import { Container, Header } from "semantic-ui-react";

import faqsContent from "data/faqsContent";

export default function FAQsPage() {
  return (
    <>
      <Header size="huge">FAQs</Header>
      <Container>
        {faqsContent.map(({question, answer}) => (
          <div>
            <Header size="medium">{question}</Header>
            <br/>
            <Header className="culpa-description">
              <p dangerouslySetInnerHTML={{ __html:answer}}/>
            </Header>
          </div>
        ))}
      </Container>
    </>
  );
}



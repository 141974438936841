import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Header, Grid, Image } from "semantic-ui-react";
import styled from "styled-components/macro";

import { ProfessorDisplayName } from "components/common/ProfessorDisplay";
import reviewArrow from "icons/to-review.png";

const propTypesNewestReviewCard = {
  reviewHeader: PropTypes.shape({ 
      professor: PropTypes.object.isRequired,
      profId: PropTypes.number.isRequired,
      profFirstName: PropTypes.string.isRequired,
      profLastName: PropTypes.string.isRequired,
      nugget: PropTypes.number.isRequired
    }).isRequired,
  submissionDate: PropTypes.string.isRequired,
  reviewId: PropTypes.number.isRequired,
  content: PropTypes.string
};

const defaultPropsNewestReviewCard = {
  content: "",
};

export default function NewestReviewCard({
  reviewHeader,
  submissionDate,
  reviewId,
  content
}) {
  return (
    <Grid stackable className="add-margin fluid" style= {{ textAlign:"left", margin: "1rem"}}>
      <Grid.Column className="newest-review-card">
        <Grid.Column>
            <Link to={`/professor/${reviewHeader.professor.profId}`}>
            <ProfessorDisplayName
                as="header"
                className="no-margin review-card-header"
                firstName={reviewHeader.professor.profFirstName}
                lastName={reviewHeader.professor.profLastName}
                nugget={reviewHeader.professor.nugget}
            />
            </Link>
        </Grid.Column>
        <Header size="small" style={{marginTop: "0rem"}}>{submissionDate}</Header>
        <OverflowContainer>
            {content}
        </OverflowContainer>
        <Link style={{ display: "flex", alignItems:"center", fontWeight: "bold", textDecoration: "underline"}} 
                    to={`/review/${reviewId}`}>
                <Image src={reviewArrow} style={{float: "left", height: "2vh"}}/>
                Go to review
        </Link>
      </Grid.Column>
    </Grid>
  );
}

NewestReviewCard.propTypes = propTypesNewestReviewCard;
NewestReviewCard.defaultProps = defaultPropsNewestReviewCard;

const OverflowContainer = styled.div`
  max-height: 10vh;
  overflow: hidden;
  margin-bottom: 3rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
import * as d3 from 'd3'
import PropTypes from "prop-types";
import React, { useRef, useEffect } from 'react'
import { Image } from "semantic-ui-react"
import styled from 'styled-components'

import EmptyStar from "icons/Empty-Star.svg"
import HalfStar from "icons/Half-Star.svg"
import Star from "icons/Stars.svg"

// Components styling
const GraphSvg = styled.svg`
    height: fit-content;
    padding: 1rem;
    @media only screen and (max-width: 600px){
      padding: 0.5rem;
    }
  `

const RatingsTitle = styled.span`
    font-family: VT323;
    font-size: 26px;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 600px){
      font-size: 20px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
`

const StarsContainer = styled.span`
    display: inline-block;
    width: fit-content
`

const RatingContainer = styled.div`
    padding: 0.5rem;
    padding-top: 1.5rem;
    width: fit-content;
    border: 1px solid var(--blue-3, #2D4F8A);
    background: var(--Light-Grey, #F2F2F2);
    border-radius: 8px;
    align-items: center;
`

const TitleContainer = styled.div`
    display: flex;
    align-items: bottom;
    width: fit-content;
`

/*
    Constants
*/ 
const labels = {
    5: "Excellent",
    4: "Great",
    3: "Fair",
    2: "Poor",
    1: "Very Poor"
}

const BAR_HEIGHT = 30
const MARGIN_TOP = 0
const MARGIN_BOTTOM = 0
const MARGIN_LEFT = 70
const WIDTH = 200
const BAR_PADDING = 0.1
const LABEL_OFFSET_X = 5
const LABEL_OFFSET_Y = -3
const FONT_SIZE = 14
const BAR_RADIUS = 5
const TICK_LABELS_OFFSET_X = -65


function getRatingsData(ratingsHistogram) {
    const ratings = []
    let avgRating = 0
    let numReviews = 0
    for (let i = 5; i > 0; i -= 1){
        if (i in ratingsHistogram) { 
            ratings.push({ 
                value: ratingsHistogram[i],
                name: labels[i],
                key: i
            })
            avgRating += i*ratingsHistogram[i]
            numReviews += ratingsHistogram[i]
        } else {
            ratings.push({ 
                value: 0,
                name: labels[i],
                key: i
            })
        }
    }
	avgRating /= numReviews
    return {
        ratings, 
        avgRating, 
        numReviews
        }
}

const Stars = ({
    avgRating,
    starSize
}) => {
    return [1, 2, 3, 4, 5].map((val) => {
        const floorRating = Math.floor(avgRating)
        if (val <= floorRating) {
            return <Image size={starSize} spaced="right" src={Star}/>
        } if (val === floorRating + 1 && avgRating % 1 !== 0) {
            return <Image size={starSize} spaced="right" src={HalfStar}/>
        }
        return <Image size={starSize} spaced="right" src={EmptyStar}/>
    })
}

const PropTypesRatings = {
    ratingsHistogram: PropTypes.objectOf(PropTypes.int).isRequired
}

const Ratings = ({
  ratingsHistogram
}) => {
    const svgRef = useRef()
    const ratingsData = getRatingsData(ratingsHistogram)
    const height = Math.ceil((ratingsData.ratings.length + BAR_PADDING) * BAR_HEIGHT)
        + MARGIN_TOP + MARGIN_BOTTOM
    const starSize = "large"
  
    useEffect(() => {
        const svg = d3.select(svgRef.current) // svg element


        // Create the SVG container.
        svg.append("svg") 
            .attr("viewBox", [0, 0, WIDTH, height])
            .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;");


        // Create a scale for the x-axis
        const x = d3.scaleLinear()
            .domain([0, d3.max(ratingsData.ratings, d => d.value)])
            .range([0, WIDTH]);

  
        // Create a scale for the y-axis
        const y = d3.scaleBand()
            .domain(ratingsData.ratings.map(d => d.name))
            .range([0, height])
            .padding(0.4)
            .align(0);


        // Add bars to the chart
        svg.selectAll(".bar-background")
            .data(ratingsData.ratings)
            .enter()
            .append("rect")
            .attr("class", "bar-background")
            .attr("x", 0)
            .attr("y", d => y(d.name))
            .attr("width", WIDTH)
            .attr("height", y.bandwidth())
            .attr("transform", `translate(${MARGIN_LEFT},0)`)
            .attr("fill", "#BCC5D5")
            .attr("stroke-width", "1")
            .attr("rx", BAR_RADIUS);
    

        // Add text to the right of each rectangle
        svg.selectAll(".bar-label")
            .data(ratingsData.ratings)
            .enter()
            .append("text")
            .attr("class", "bar-label")
            .attr("x", WIDTH + LABEL_OFFSET_X)
            .attr("y", d => y(d.name) + BAR_HEIGHT / 2 + LABEL_OFFSET_Y)
            .attr("transform", `translate(${MARGIN_LEFT}, 0)`)
            .text(d => `(${d.value})`)
            .style("font-size", FONT_SIZE)
            .style("alignement-baseline", "middle")
            .style("font-family", "VT323")


        // Add bars to the chart
        svg.selectAll(".bar")
            .data(ratingsData.ratings)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .attr("x", 0)
            .attr("y", d => y(d.name))
            .attr("transform", `translate(${MARGIN_LEFT}, 0)`)
            .attr("width", d => x(d.value))
            .attr("height", y.bandwidth())
            .attr("fill", "#2D4F8A")
            .attr("stroke-width", "1")
            .attr("rx", BAR_RADIUS);


        // Add the x-axis
        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(${MARGIN_LEFT},${height})`)
            .call(d3.axisBottom(x).ticks(0))
            .style("stroke-width", 0)
            .style("font-family", "VT323");

        // Add the y-axis
        svg.append("g")
            .attr("class", "y-axis")
            .attr("transform", `translate(${MARGIN_LEFT + TICK_LABELS_OFFSET_X}, 0)`)
            .call(d3.axisLeft(y).tickSize(0))
            .style("font-family", "VT323")
            .style("font-size", FONT_SIZE)
            .style("stroke-width", 0)
            .attr("text-anchor", "left");

            
        return () => {

        };
    }, [height, ratingsData.ratings]);

    // No reviews available, don't show graph.
    if (Number.isNaN(ratingsData.avgRating)) {
      return (
        <></>
      )
    }
  
    return (
      <RatingContainer>
        <link href="https://fonts.googleapis.com" rel="preconnect"/>
        <link href="https://fonts.gstatic.com" rel="preconnect"/>
        <link href="https://fonts.googleapis.com/css2?family=VT323&display=swap" rel="stylesheet"/>
        <TitleContainer>
          <RatingsTitle>Ratings</RatingsTitle>
          <StarsContainer>
            <Stars avgRating={ratingsData.avgRating.toFixed(2)} starSize={starSize}/>
          </StarsContainer>
          <RatingsTitle>{ratingsData.avgRating.toFixed(2)}</RatingsTitle>
        </TitleContainer>
        <GraphSvg ref={ svgRef }/>
      </RatingContainer>
    );
  };

Ratings.propTypes = PropTypesRatings;
export default Ratings;
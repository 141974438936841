import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Container, Divider, Grid, Header } from "semantic-ui-react";

import ActionMenu from './ActionMenu';

const ACCEPT = "Accept Course";
const REJECT = "Reject Course";

export default function CoursesList() {
    const [courses, setCoursesList] = useState([]);
    const [courseCount, setCourseCount] = useState(0);

    const fetchCourses = () => {
        fetch(`/api/course/get/pending`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                throw response;
            })
            .then(response => response.json())
            .then(json => {
                setCoursesList(json.courses)
                setCourseCount(json.courses.length)
            })
            .catch(error => { return error })
    }

    const updateCourse = async (courseId, status) => {
        let newStatus = 'pending'
        if (status === ACCEPT) {
            newStatus = 'approved'
        } else if (status === REJECT) {
            newStatus = 'rejected'
        }

        const response = await fetch(`/api/course/put/${courseId}/${newStatus}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
        });
        try {
            const result = await response.json();
            fetchCourses()

            return result
        } catch (err) {
            return { error: err };
        }
    };

    useEffect(() => {
        fetchCourses()
    }, [])

    return (
        <Container>
            <Header size="large">{courseCount} Courses</Header>
            <Container>
                <Header size="medium">Pending Courses:</Header>
                {courses && courses.map((course) => (
                    <CourseCard course={course} updateCourse={updateCourse} />
                ))}
            </Container>
        </Container>
    )
}

const propTypesCourseCard = {
    course: PropTypes.shape({
        courseId: PropTypes.number.isRequired,
        courseName: PropTypes.string.isRequired,
        courseCode: PropTypes.string.isRequired,
        departmentName: PropTypes.string.isRequired,
        departmentId: PropTypes.string.isRequired,
    }).isRequired,
    updateCourse: PropTypes.func.isRequired,
};

const CourseCard = ({ course, updateCourse }) => {
    return (
        <Container>
            <Container className="course-card">
                <Grid columns={2}>
                    <Grid.Column>
                        <Header className="no-margin review-card-header">{course.courseName}</Header>
                        <br />
                        <Header size="small">{course.courseCode === "" || !course.courseCode ? "No Course Code" : course.courseCode}</Header>
                        <br />
                        <Header size="small">Department: {course.departmentName}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button basic>Edit</Button>
                    </Grid.Column>
                </Grid>
            </Container>
            <ActionMenu handleSubmit={updateCourse} itemId={course.courseId} options={[ACCEPT, REJECT]} />
            <Divider />
        </Container>
    )
}

CourseCard.propTypes = propTypesCourseCard;

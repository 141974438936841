import React from 'react'
import { Header } from "semantic-ui-react";

import ErrorComponent from './common/ErrorComponent';
import LoadingComponent from './common/LoadingComponent';
import useDataFetch from './common/useDataFetch';
import ReviewCard from './reviews/ReviewCard';

const NewestReviewsPage = () => {
    const reviewDataFetched = useDataFetch(
        `/api/review/get/newest/10`,
        {
          reviews: [],
        }
    );
    
    const { reviews } = reviewDataFetched.data;
    const isReviewLoading = reviewDataFetched.isLoading;
    const isReviewError = reviewDataFetched.isError;
    let newestReviews; 

    if (isReviewLoading || isReviewError) {
        newestReviews = isReviewLoading ? <LoadingComponent/> : <ErrorComponent/>;
    }
    else{
        newestReviews = reviews.map((review)=>{
        return(
            <ReviewCard
                content={review.content}
                deprecated={review.deprecated}
                reviewHeader={review.reviewHeader}
                reviewId={review.reviewId}
                reviewType={review.reviewType}
                submissionDate={review.submissionDate}
                votes={review.votes}
                workload={review.workload}
            />
        )
        })
    }
    
  return (
    <div>
        <Header size="huge">Newest Reviews</Header>
        <br/>
        <Header size="big" style={{ fontWeight : "400", marginBottom:"2rem"}}>CULPA is constantly updated. Read our newest reviews.</Header>
        {newestReviews}
    </div>
  )
}

export default NewestReviewsPage
import PropTypes, { oneOfType } from "prop-types";
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Button, Container, Grid, Icon, Image, Menu } from "semantic-ui-react";
import styled from "styled-components";

import CreateReviewButton from "components/common/CreateReviewButton";
import Footer from "components/common/Footer";
import Form from "components/common/Form";
import { SearchInput } from "components/common/Inputs";
import Sidebar from "components/common/Sidebar";
import culpaIcon from "icons/culpa.svg";

const propTypes = {
  children: oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

const defaultProps = {
  children: [],
};

export default function NavigationBar({ children }) {
  return <NavBar>{children}</NavBar>;
}

function NavBar({ children }) {
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };

  const history = useHistory();

  const onSearchSubmit = ({ NavbarSearchbar }) => {
    const searchValue = NavbarSearchbar;
    if (typeof searchValue === "string" && searchValue.length > 1) {
      history.push(`/search?entity=all&query=${searchValue}&alphabetize=True`);
    }
    return {}; // Form requires output
  };

  const onResultSelect = ({ type, id }) => {
    history.push(`/${type}/${id}`);
  };

  return (
    <>
      <Grid
        as={Menu}
        className="navbar"
        fixed="top"
        textAlign="center"
        verticalAlign="middle"
      >
        <Row>
          <Button basic compact onClick={showSidebar}>
            <Icon fitted color="blue" name="bars" size="big" />
          </Button>

          <Link className="culpa-img" to="/">
            <Image src={culpaIcon} />
          </Link>

          <Form onSubmit={onSearchSubmit} onSuccess={() => {}}>
            <SearchInput
              name="NavbarSearchbar"
              placeholder="Search for professors or courses"
              searchEntity="all"
              searchLimit={6}
              onResultSelect={onResultSelect}
            />
          </Form>

          <CreateReviewButton fluid color="orange" />
        </Row>
      </Grid>
      <Container className="root-container">
        <Sidebar hideSidebar={hideSidebar} isSidebarVisible={isSidebarVisible}>
          {children}
        </Sidebar>
      </Container>
      <Footer />
    </>
  );
}

NavigationBar.propTypes = propTypes;
NavigationBar.defaultProps = defaultProps;

NavBar.propTypes = propTypes;
NavBar.defaultProps = defaultProps;

const Row = styled.div`
  flex-direction: row;
  display: flex;
  margin: 1.5rem 0.5rem;
  width: 100%;
  align-items: center;
  .compact {
    margin: 0 0.75rem 0.25rem 0;
  }

  .form {
    width: 100%;
    margin: 0 0.75rem;
  }
  @media screen and (max-width: 768px) {
    .form {
      margin: 0 0.25rem;
    }
  }
`;

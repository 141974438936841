import React from "react";
import { Link } from "react-router-dom";
import { Grid, Icon, Image } from "semantic-ui-react";

import culpaIcon from "icons/culpa.svg";

/* import projectalpha from "icons/projectalphawhitelogo.png"; */

export default function Footer() {
  return (
    <Grid stackable className="footer" columns={2} verticalAlign="middle">
      <Grid.Column textAlign="center">
        <Image centered src={culpaIcon} style={{height: '2rem'}}/>
        <Icon color="blue" name="copyright outline" size="small" />
        <span style={{ color: "#82B7E8" }}>1997-2022</span>
      </Grid.Column>
      <Grid.Column textAlign="left">
        <Grid.Row>
          <Link className="footer-link" to="/privacy">
            Privacy Policy
          </Link>
        </Grid.Row>
        <Grid.Row>
          <Link className="footer-link" to="/terms-of-use">
            Terms of Use
          </Link>
        </Grid.Row>
        <Grid.Row>
          <Icon color="blue" name="mail" size="small" />
          <a href="mailto:admin@culpa.info"><span className="footer-link">admin@culpa.info</span></a>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
}

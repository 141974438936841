import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Container, Divider, Grid, Header, Input } from "semantic-ui-react";

import ActionMenu from './ActionMenu';

const ACCEPT = "Accept Professor";
const REJECT = "Reject Professor";
const UPDATE = "Update Professor"

export default function ProfessorsList() {
    const [professors, setProfessorsList] = useState([]);
    const [professorCount, setProfessorCount] = useState(0);

    const fetchProfessors = () => {
        fetch(`/api/professor/get/pending`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                throw response;
            })
            .then(response => response.json())
            .then(json => {
                setProfessorsList(json.professors)
                setProfessorCount(json.professors.length)
            })
            .catch(error => { return error })
    }

    const updateProfessor = async (professorId, status, tempValues=null) => {
        let newStatus = 'pending'
        if (status === ACCEPT) {
            newStatus = 'approved'
        } else if (status === REJECT) {
            newStatus = 'rejected'
        } else if (status === UPDATE) {
            newStatus = 'update'
        }

        const response = await fetch(`/api/professor/put/${professorId}/${newStatus}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(tempValues),
        });
        try {
            const result = await response.json();
            fetchProfessors()

            return result
        } catch (err) {
            return { error: err };
        }
    };

    useEffect(() => {
        fetchProfessors()
    }, [])

    return (
        <Container>
            <Header size="large">{professorCount} Professors </Header>
            <Container>
                <Header size="medium">Pending Professors:</Header>
                {professors && professors.map((professor) => (
                    <ProfessorCard professor={professor} updateProfessor={updateProfessor}/>
                ))}
            </Container>
        </Container>
    )
}

const propTypesProfessorCard = {
    professor: PropTypes.shape({
        professorId: PropTypes.number.isRequired,
        professorFirstName: PropTypes.string.isRequired,
        professorLastName: PropTypes.string.isRequired,
        professorUNI: PropTypes.string.isRequired,
    }).isRequired,
    updateProfessor: PropTypes.func.isRequired
};

const ProfessorCard = ({ professor, updateProfessor }) => {
    const [editing, setEditing] = useState(false);

    const handleEditing = () => {
        if(editing){
            setEditing(false);
        }else{
            setEditing(true);
        }
    };

    const viewMode = {};
    const editMode = {};
    if (editing) {
        viewMode.display = 'none';
    } else {
        editMode.display = 'none';
    }

    const tempValues = {
        firstName: professor.professorFirstName,
        lastName: professor.professorLastName,
        uni: professor.professorUNI
    }

    const setUpdate = (updatedTitle, id) => {
        if(updatedTitle === ""){
            if(id === professor.professorFirstName){
                tempValues.firstName = professor.professorFirstName;
            }
            else if (id === professor.professorLastName) {
                tempValues.lastName = professor.professorLastName;
            }
            else {
                tempValues.uni = professor.professorUNI;
            }
            return;
        }

        if(id === professor.professorFirstName){
            tempValues.firstName = updatedTitle;
        }
        else if (id === professor.professorLastName) {
            tempValues.lastName = updatedTitle;
        }
        else {
            tempValues.uni = updatedTitle;
        }
    };

    
    return (
        <Container>
            <Container className="course-card">
                <Grid columns={2}>
                    <Grid.Column>
                        <Header className="no-margin review-card-header" style={viewMode}>{professor.professorFirstName}{" "}{professor.professorLastName}</Header>
                        <div>
                            <Input 
                                class="ui input focus" 
                                placeholder={professor.professorFirstName}
                                style={editMode} 
                                type="text" 
                                onChange={(e) => setUpdate(e.target.value, professor.professorFirstName)}
                            />
                            <Input 
                                class="ui input focus" 
                                placeholder={professor.professorLastName}
                                style={editMode} 
                                type="text" 
                                onChange={(e) => setUpdate(e.target.value, professor.professorLastName)}
                            />
                        </div>
                        <br />
                        <Header size="small" style={viewMode}>{professor.professorUNI === "" || !professor.professorUNI ? "No Professor UNI" : professor.professorUNI}</Header>
                        <Input
                            class="ui input focus" 
                            placeholder={professor.professorUNI === "" || !professor.professorUNI ? "No Professor UNI" : professor.professorUNI} 
                            style={editMode}
                            type="text"
                            onChange={(e) => setUpdate(e.target.value, professor.professorUNI)}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button basic style={viewMode} onClick={handleEditing}>Edit</Button>
                        <Button basic style={editMode} onClick={() => {handleEditing(); updateProfessor(professor.professorId, UPDATE, tempValues);}}>Save</Button>
                    </Grid.Column>
                </Grid>
            </Container>
            <ActionMenu handleSubmit={updateProfessor} itemId={professor.professorId} options={[ACCEPT, REJECT]} />
            <Divider />
        </Container>
    )
}

ProfessorCard.propTypes = propTypesProfessorCard;
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import styled from "styled-components";

const propTypesSyllabusLink = {
    syllabus: PropTypes.shape({
        syllabusId: PropTypes.number.isRequired,
        profFirstName: PropTypes.string.isRequired,
        profLastName: PropTypes.string.isRequired,
        courseName: PropTypes.string.isRequired,
        courseCode: PropTypes.string.isRequired,
    }).isRequired,
};

const SyllabusLink = ({ syllabus }) => {
    const handleOpenPdf = async () => {
        const response = await axios.get(`/api/sillabi/${syllabus.syllabusId}`, {
        responseType: 'arraybuffer',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
    };

    let linkString = ``
    if (syllabus.profFirstName === "")
    {
        linkString = `[${syllabus.courseCode}] ${syllabus.courseName}`;
    }
    else
    {
        linkString = `[${syllabus.courseCode}] ${syllabus.courseName} - ${syllabus.profFirstName} ${syllabus.profLastName}`;
    }
    
    return (
        <SyllabusButton
        type="button"
        onClick={() => handleOpenPdf()}
        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                handleOpenPdf();
            }
        }}>
            {linkString}
        </SyllabusButton>
    );
};

const SyllabusButton = styled.button`
    cursor: pointer;
    color: #0072ce; 
    text-decoration: none;
    border: none;
    outline: none;
    background: none;
    margin-bottom: 1rem;
    font-size: 1rem;
    letter-spacing: 0.05rem;
`

SyllabusLink.propTypes = propTypesSyllabusLink;

export default SyllabusLink;

import React from "react";
import { Link } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";
import styled from "styled-components";

export default function TermsOfUsePage() {

  return (
    <>

      <Header size="huge">TERMS OF USE</Header>
      <ContentContainer>
        <p>This page describes the terms and conditions that govern your use of the services provided by CULPA.</p>
        <p>These terms of use are entered into between you and Spectator Publishing Company, a company formed under the laws of the State of New York (the &quot;Company&quot;). This is the official Terms of Use Agreement (&quot;Agreement&quot;) for the culpa.info website, application or other interactive service (&quot;Site&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;). The Site is owned, operated and/or provided by the Company.</p>
        <p>The following agreement, together with any documents it expressly incorporates by reference (collectively, this &quot;agreement&quot;), govern your access to and use of the Site, including any content, functionality, and services offered on or through the Site, whether as a guest or a registered user.</p>
        <p>Please read this agreement carefully before you start to use the Site. By using the Site, you accept and agree to be bound and abide by this Agreement and the Company&apos;s Privacy Policy, found at https://culpa.info/privacy, incorporated by reference. If you do not want to agree to this Agreement or the <Link to='/privacy'>Privacy Policy</Link>, you must not access or use the Website.</p>
        <p>There are age and other eligibility requirements for this Site. This Site is governed by and operated under U.S. law.</p>

        <Header size="small">1. Modifications to this Agreement</Header>
        <p>We may modify this Agreement from time to time. We shall post or display notices of material changes on the Site; the form of such notice is at our discretion. Once we post them on the Site, these changes become effective immediately and if you use the Site after they become effective it will signify your agreement to be bound by the changes.</p>

        <Header size="small">2. Rule of Conduct</Header>
        <p>Your use of the Site is subject to all applicable laws, rules and regulations and, in some cases, international treaties. You are solely responsible for all activities, acts and omissions that occur in, from or through your submissions of User Contributions. You are also prohibited from using the Site (or attempting to use the Site, or allowing, enabling or condoning others to use the Site) in a manner that:</p>
        <Container>
          <li>is or is likely to be libelous, defamatory, indecent, vulgar or obscene, pornographic, sexually explicit or sexually suggestive, racially, culturally, or ethnically offensive, harmful, harassing, intimidating, threatening, hateful, objectionable, discriminatory, or abusive, or which may or may appear to impersonate anyone else;</li>
          <li>uses the Site for commercial or business purposes, including, without limitation, advertising, marketing or offering goods or services, whether or not for financial or any other form of compensation or through linking with any other website or web pages;</li>
          <li>introduces any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;</li>
          <li>violates any laws, rules or regulations (including, without limitation, laws regarding the transmission of technical data or software exported from the United States), judicial or governmental order or any treaties, or violates or infringes upon any copyright or other intellectual property rights, rights of publicity or privacy or any other rights of ours or of any other person, firm or enterprise;</li>
          <li>attempts to gain, gains or enables unauthorized access to the Site or any of its features or functions, other users&apos; accounts, names, passwords, personally identifiable information or other computers, websites or pages, connected or linked to the Site;</li>
          <li>modifies, disrupts, impairs, alters or interferes with the use, features, functions, operation or maintenance of the Site or the use and enjoyment of the Site by any other person, firm or enterprise;</li>
          <li>collects, obtains, compiles, gathers, transmits, reproduces, deletes, revises, views or displays any material or information, whether personally identifiable or not, posted by or concerning any other person, firm or enterprise, in connection with their or your use of the Site, unless you have obtained the express, prior permission of such other person, firm or enterprise to do so;</li>
          <li>otherwise violates or is inconsistent with this Agreement, including, but not limited to, any Additional Terms and the <span><Link to='/privacy'>Privacy Policy</Link></span>; or</li>
          <li>otherwise interferes with the proper working of the Site.</li>
        </Container>

        <Header size="small">3. User Contributions</Header>
        <p>Portions of this Site provide you and other users an opportunity to submit, post, display, transmit and/or exchange information, ideas, opinions, messages, content, media, materials or other information (collectively, &quot;User Contributions&quot;). If this Site provides a User Content Submission Agreement, such terms govern any User Content (as defined therein) which are also User Contributions under this Agreement and such User Content Submission Agreement are Additional Terms under this Agreement.</p>

        <Header size="small">4. Responsibility for User Contributions; Our Right to Review, Monitor, Edit and/or Screen and Take Other Actions</Header>
        <p>You understand, acknowledge and agree that User Contributions are the sole responsibility of the person from which such User Contributions originated. This means that you are solely and entirely responsible for the consequences of all User Contributions that you upload, post, email, transmit or otherwise make available via the Site. Except for the licenses, authorizations, consents and rights granted hereunder (including, but not limited to, as described in any User Content Submission Agreement that may be posted on this Site), as comprehensive and broad as they may seem or actually be, the Company acquires no title or ownership rights in or to any User Content you submit and nothing in this Agreement conveys to us any ownership rights in your User Contributions.</p>
        <p>User Contributions do not reflect the views of the Company and the Company does not represent or guarantee the truthfulness, accuracy or reliability of any User Contribution or endorse or support any opinions expressed in any User Contributions. The Company does not control any User Contributions submitted, although we may (in our sole discretion) use automated and/or manual means of reviewing User Contributions to prevent unauthorized content from appearing and being displayed on our Site. The Company reserves the right to review, monitor, edit and/or screen any User Contributions and to delete, remove, move, re-format, edit or reject, without notice to you, any User Contributions that we deem, in our sole discretion, to be in violation of this Agreement or to be unacceptable to the Company, or for any reason or for no reason whatsoever; provided, however, that the Company shall have no obligation or liability for failure to do so or for doing so in any particular manner. You will not be responsible for any changes we make to your User Contributions only to the extent that such changes alter the meaning of your User Contributions.</p>
        <p>If we determine, in our sole discretion and judgment, that any User Contribution does or may violate any of the terms of this Agreement, in addition to our rights as described in this Agreement, we reserve the right, at any time and without limiting any and all other rights we may have under this Agreement, at law or in equity, to: (i) refuse to allow you to Post; (ii) remove and delete User Contributions; (iii) revoke your right to use the Site; and/or (iv) use any technological, legal, operational or other means available to us to enforce the provisions of this Agreement, including, without limitation, blocking specific IP addresses or deactivating your registration on the Site.</p>
        <p>Some User Contributions may not be processed due to space constraints or outbound message limitations. You understand, acknowledge and agree that we assume no responsibility for distribution of or deletion of User Contributions or any failure to store, receive or deliver User Contributions in a timely manner, or at all, or any other matter relating to User Contributions. User Contributions are subject to all of the terms of this Agreement, including, but not limited to, the Rules of Conduct.</p>

        <Header size="small">5. Grant of Rights; Representations, Warranties and Covenants as to User Contributions</Header>
        <p>If a User Contribution originates from you, you hereby agree that (and each time you submit (or attempt to submit) a User Contribution, you reaffirm such agreement that):</p>
        <p>(i) You currently are, or in the past have been, enrolled in a school or division of Columbia University, and are or have been enrolled in or audited any class as to which you submit a User Contribution.</p>
        <p>(ii) you specifically authorize the Company to use such User Contribution in whole or in part, throughout the universe, in perpetuity in or on any and all media, now known or hereafter devised, and alone or together with or as part of other information, content and/or material of any kind or nature;</p>
        <p>(iii) you represent, warrant and covenant that (A) the User Contribution is original to you and/or fully cleared for use as contemplated herein, (B) the User Contribution does and will not, in any way, violate or breach any of the terms of this Agreement, (C) the User Contribution does not contain libelous, tortious, obscene or otherwise unlawful material or information, (D) the User Contribution, and the Company&apos;s exercise of the rights you grant to the Company to the User Contributions, will not infringe upon, misappropriate or otherwise violate any copyright or other intellectual property, privacy, publicity or other rights of any party, or otherwise violate any other applicable law, rule or regulation, (E) the User Contribution shall not be injurious to the health of any user, (F) the Company shall not be required to pay or incur any sums to any person or entity as a result of our permitted use or exploitation of the User Contribution, and (G) all of the information provided by you associated with your User Contribution is complete and accurate; and</p>
        <p>(vi) if your User Contribution incorporates the name, logo, brand, service or trademark, voice, likeness or image of any person, firm or enterprise, you additionally represent, warrant and covenant to the Company that you have the right to grant the Company the right to use all such User Contributions as described above.</p>
        <p>Notwithstanding anything to the contrary, you also understand and agree that the Company shall not be obligated or responsible for providing any guild or residual payments in connection with the submission and exploitation of any User Contribution.</p>

        <Header size="small">6. Claims Regarding Content</Header>
        <p>If you believe that any content on the Site (including, without limitation, User Contributions) violates any of the terms of this Agreement (except for any notices covered by the Copyright Compliance Policy), you may contact Spectator&apos;s designated Copyright Agent, our Publisher, publisher@columbiaspectator.com, 490 Riverside Drive, New York, NY 10027. We cannot guarantee that we will respond to your message and we reserve the right to take or refrain from taking any or all steps available to us once we receive any such message.</p>


        <Header size="small">7. Certain Rights of Removal for User Contributions</Header>
        <p>Users of this Site who are California residents and are under 18 years of age may request and obtain removal of User Contributions on this Site that they themselves post by emailing us at admin@culpa.info All requests must be submitted with &quot;California Removal Request&quot; as the category. All requests must provide a description of the content or information in your User Contribution that you want removed and information reasonably sufficient to permit us to locate the material and, so that we can process your request and contact you if we have questions, include your name, street address, city, state, zip code and email address (your street address is optional if you wish to receive a response to your request via email), include the name and URL (if applicable) of the website, application or other interactive service and indicate your preference on how our response to your request should be sent (email or postal mail). We shall not accept requests via postal mail, telephone or facsimile. We are not responsible for notices that are not labeled or sent properly, or may not be able to respond if you do not provide complete information.</p>
        <p>Please also note that any requests for removal do not ensure complete or comprehensive removal of the content or information from this Site. For example, content that you have posted may be republished or reposted by another user or third party. </p>

        <Header size="small">8. Advertising</Header>
        <p>From time to time, you may communicate with, receive communications from, be redirected to, interact with, or participate in or use the services or obtain goods and services of or from, third parties (collectively, the &quot;Advertisers&quot;), such as our advertisers, sponsors, or promotional partners, as a result of your use of the Site. All such communication, interaction and participation is strictly and solely between you and such Advertisers and, to the fullest extent permissible by law, we shall not be responsible or liable to you in any way in connection with these activities or transactions (including, without limitation, any representations, warranties, covenants, contracts or other terms or conditions that may exist between you and the Advertiser or any goods or services you may purchase or obtain from any Advertiser).</p>

        <Header size="small">9. Hyperlinks from the Site</Header>
        <p>If the Site contains links to other websites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. The Company has no control over the contents of those websites or resources and accepts no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Site, you do so entirely at your own risk and subject to the terms and conditions of use for those websites.</p>

        <Header size="small">10. Disclaimer and Limitations of Liability</Header>
        <p>THIS SITE, AND ALL MATERIALS, GOODS AND SERVICES AND POSTINGS ARE MADE AVAILABLE ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED (INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE) OR ANY GUARANTY OR ASSURANCE THE SITE WILL BE AVAILABLE FOR USE, OR THAT ALL FEATURES, FUNCTIONS OR OPERATIONS WILL BE AVAILABLE OR PERFORM AS DESCRIBED. WITHOUT LIMITING THE FOREGOING, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE ARE NOT RESPONSIBLE OR LIABLE FOR ANY MALICIOUS CODE, DELAYS, INACCURACIES, ERRORS, OR OMISSIONS RELATING TO YOUR USE OF THE SITE. YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, YOU ARE ASSUMING THE ENTIRE RISK AS TO THE QUALITY, ACCURACY, PERFORMANCE, TIMELINESS, ADEQUACY, COMPLETENESS, CORRECTNESS, AUTHENTICITY, SECURITY AND VALIDITY OF ANY AND ALL FEATURES AND FUNCTIONS OF THE SITE, INCLUDING, WITHOUT LIMITATION, POSTINGS AND MATERIALS ASSOCIATED WITH YOUR USE OF THE SITE.</p>
        <p>YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, THE COMPANY, AND ITS RESPECTIVE SUCCESSORS AND ASSIGNS, AND ANY OF ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS, OPERATIONAL SERVICE PROVIDERS, ADVERTISERS, OR SUPPLIERS, SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE OF ANY KIND, DIRECT OR INDIRECT, RELATING TO THE SITE OR THIS AGREEMENT, INCLUDING, BUT NOT LIMITED TO, COMPENSATORY, CONSEQUENTIAL, INCIDENTAL, DIRECT, INDIRECT, SPECIAL OR PUNITIVE DAMAGES, HOWEVER ARISING OR CAUSED, WHETHER FORESEEABLE OR NOT, REGARDLESS OF THE FORM OF ACTION OR THE BASIS OR CHARACTERIZATION OF THE CLAIM AND EVEN IF ADVISED OF THE POSSIBILITY OF DAMAGES. WITHOUT LIMITING THE FOREGOING, THE SITE IS MAINTAINED ON SERVERS IN THE UNITED STATES AND THE COMPANY DISCLAIMS ALL LIABILITY FOR ANY USE NOT SPECIFICALLY AUTHORIZED OR THAT IS IN VIOLATION OF THIS AGREEMENT OR THE LAWS OR REGULATIONS THAT MAY APPLY TO YOU IN ANY JURISDICTION OR COUNTRY.</p>
        <p>NOTWITHSTANDING ANY CLAIM THAT A SOLE OR EXCLUSIVE REMEDY WHICH IS PROVIDED IN THIS AGREEMENT MAY OR DOES FAIL OF ITS ESSENTIAL PURPOSE, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY LOSS OR DAMAGE SHALL BE LIMITED TO HAVING THE COMPANY, UPON WRITTEN NOTICE FROM YOU TO US, ATTEMPT TO REPAIR, CORRECT OR REPLACE ANY DEFICIENT GOODS OR SERVICES UNDER THIS AGREEMENT AND, IF REPAIR, CORRECTION OR REPLACEMENT IS NOT REASONABLY COMMERCIALLY PRACTICABLE FOR THE COMPANY, TO REFUND ANY MONIES ACTUALLY PAID BY YOU TO THE COMPANY FOR THE GOODS OR SERVICES INVOLVED AND TO TERMINATE AND DISCONTINUE YOUR USE OF THE SITE. YOU FURTHER UNDERSTAND AND ACKNOWLEDGE THE CAPACITY OF THE SITE, IN THE AGGREGATE AND FOR EACH USER, IS LIMITED. CONSEQUENTLY, SOME MESSAGES AND TRANSMISSIONS MAY NOT BE PROCESSED IN A TIMELY FASHION OR AT ALL, AND SOME FEATURES OR FUNCTIONS MAY BE RESTRICTED OR DELAYED OR BECOME COMPLETELY INOPERABLE. AS A RESULT, YOU ACKNOWLEDGE AND AGREE THAT THE COMPANY ASSUMES NO LIABILITY, RESPONSIBILITY OR OBLIGATION TO TRANSMIT, PROCESS, STORE, RECEIVE OR DELIVER TRANSACTIONS OR POSTINGS OR FOR ANY FAILURE OR DELAY ASSOCIATED WITH ANY POSTINGS AND YOU ARE HEREBY EXPRESSLY ADVISED NOT TO RELY UPON THE TIMELINESS OR PERFORMANCE OF THE SITE FOR ANY TRANSACTIONS OR POSTINGS. SOME JURISDICTIONS DO NOT ALLOW FOR THE EXCLUSION OR LIMITATION OF CERTAIN WARRANTIES OR THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE EXCLUSIONS AND LIMITATIONS DESCRIBED IN THIS AGREEMENT MAY NOT APPLY TO YOU.</p>
        
        <Header size="small">11. Indemnification</Header>
        <p>You agree to indemnify, defend and hold the Site, the Company, and any of their successors and assigns, and any of their respective officers, directors, employees, agents, representatives, licensors, Advertisers, suppliers, and operational service providers harmless from and against any and all claims, actions, losses, expenses, damages and costs (including reasonable attorneys&apos; fees), resulting from any breach or violation of this Agreement by you.</p>
        <p>The Company reserves the right to assume, at its sole expense, the exclusive defense and control of any such claim or action and all negotiations for settlement or compromise, and you agree to fully cooperate with the Company in the defense of any such claim, action, settlement or compromise negotiations, as requested by The Company.</p>

        <Header size="small">12. Privacy</Header>
        <p>We respect your privacy and the use and protection of your Information. Please see our <Link to='/privacy'>Privacy Policy</Link>, for important information and disclosures relating to the collection and use of your Information in connection with your use of the Site.</p>

        <Header size="small">13. Governing Law, Jurisdiction, Venue and Jury Trial Waiver</Header>
        <p>This Agreement and your use of the Site is governed by, construed and enforced in accordance with the laws of the State of New York (without regard to that state&apos;s conflict of laws rules), and you and the Company agree that any claim or dispute under this Agreement is subject to exclusive jurisdiction and venue in the State or Federal Courts situated in the Borough of Manhattan, New York City, State of New York. To the extent it may be applicable, you and the Company agree to opt out from and expressly exclude any applicability of the Uniform Computer Information Transactions Act. If any Claim proceeds in court, YOU AND THE COMPANY WAIVE ANY RIGHT TO A JURY TRIAL.</p>

        <Header size="small">14. Miscellaneous Terms</Header>
        <p>This Agreement, together with any Additional Terms, our <Link to='/privacy'>Privacy Policy</Link> and any other regulations, procedures and policies which we refer to and which are hereby incorporated by reference, contains the entire understanding and agreement between you and the Site and supersedes any and all prior or inconsistent understandings relating to the Site and your use of the Site. This Agreement cannot be changed or terminated orally and may not be modified except as described in this Agreement. If any provision of this Agreement is held to be illegal, invalid or unenforceable, this will not affect any other provisions and the Agreement will be deemed amended to the extent necessary to make it legal, valid and enforceable. Any provision which must survive in order to allow us to enforce its meaning shall survive the termination of this Agreement; however, no action relating to this Agreement or your use of the Site, regardless of form or the basis of the claim, may be brought by you more than one (1) year after the cause of action has arisen (or if multiple causes, from the date the first such cause arose). The laws and regulations of the U.S. restrict the export and re-export of certain technology and you agree not to use, export or re-export any Material relating to the Site in violation of those laws and regulations. The section titles in this Agreement are for convenience only and do not have any legal effect.</p>
        <p>This Terms of Use Agreement was last modified on November 15, 2022 and is effective immediately. </p>
      </ContentContainer>
    </>
  );
}

const ContentContainer = styled.div`
  p {
    margin: 0.5rem 0rem;
  }

  li {
    margin-left: 4rem;
  }
`



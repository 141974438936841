/** Renders Ad from our DFP Ad Slot provider, based on props passed in
 *  Inspired by : https://github.com/oahehc/dfp-examples/tree/master/react-dfp/src
 *
 *  Example AdSlot Sizes                (width, height)
 *      cds_horizontal_box              (720, 300)
 *      cds_horizontal_box_mobile       (300, 250)
 *      house                           (375, 90)
 *      cds_leaderboard                 (728, 90)
 *      cds_leaderboard_mobile          (320, 50)
 *      cds_vertical_box                (300, 600)
 */
import PropTypes from "prop-types";
import React from "react";
import { DFPSlotsProvider, AdSlot } from "react-dfp";

const DFPAdSlot = ({height, path, width}) => {
  return (
    <DFPSlotsProvider dfpNetworkId="59699124">
      <div style={{margin: "2rem"}}>
        <AdSlot
          adUnit={path}
          class="adslot"
          sizes={[[width, height]]}
        />
      </div>
    </DFPSlotsProvider>
  );
};

DFPAdSlot.propTypes = {
  height: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default DFPAdSlot;
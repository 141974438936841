import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components/macro";

const QuoteContainer = styled.div`
  margin: 20px 0;
  padding: 1.5rem;
  background-color: #F2F2F2;
  border-radius: 0.5rem;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const QuoteMark = styled.span`
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  font-size: 3rem;
  color: #82B7E8;
`;

const QuoteId = styled.div`
  position: absolute;
  top: 1rem; 
  right: 1rem;
  color: #1d2c4d;
  font-weight: 800;
  font-size: 0.875rem;
`;

const QuoteFooter = styled.div`
  margin-top: 3rem;
  text-align: center;
  color: #1d2c4d;
  font-weight: 800;
  font-size: 0.875rem;
`;

const QuoteText = styled.p`
  margin: 2rem;
  color: #1D2C4D;
  font-size: 1.25rem;
  font-weight: 1000;
  text-align: center;
  font-family: 'Source Code Pro', monospace;
  line-height: 3rem;
`;

const CourseText = styled.span`
  color: #0072CE;
`;

const formatDate = (date) => {
  if (!date) return ''; // Handle case when date is undefined or null
  const isoString = new Date(date).toISOString(); // Convert date to ISO string
  return isoString.split('T')[0]; // Extract yyyy-mm-dd part
};

const Quote = ({ quoteId, content, firstName, lastName, courseName, courseCode, quoteDate }) => (
  <QuoteContainer>
    <QuoteId>ID: {quoteId}</QuoteId>
    <QuoteText>
      <QuoteMark>&ldquo;</QuoteMark>
      {content}
      <QuoteMark>&rdquo;</QuoteMark>
    </QuoteText>
    <QuoteFooter>
    - {firstName}{" "}{lastName} in <CourseText>{courseName}{" ("}{courseCode}{")"}</CourseText> on {formatDate(quoteDate)}
    </QuoteFooter>
  </QuoteContainer>
);

Quote.propTypes = {
  quoteId: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  firstName:  PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  quoteDate: PropTypes.instanceOf(Date).isRequired,
  courseName: PropTypes.string.isRequired,
  courseCode: PropTypes.string.isRequired
};

export default Quote;
import PropTypes from "prop-types";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { Divider, Grid, Header } from "semantic-ui-react";
import styled from 'styled-components'

import { CourseDisplayLink } from "components/common/CourseDisplay";
import { DepartmentDisplayName } from "components/common/DepartmentDisplay";
import ErrorComponent from "components/common/ErrorComponent";
import LoadingComponent from "components/common/LoadingComponent";
import { ProfessorDisplayLink } from "components/common/ProfessorDisplay";
import useDataFetch from "components/common/useDataFetch";

const propTypesDepartmentCourses = {
  departmentCourses: PropTypes.arrayOf(
    PropTypes.shape({
      courseId: PropTypes.number.isRequired,
      courseName: PropTypes.string.isRequired,
      courseCode: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export function DepartmentCourseColumn({ departmentCourses }) {
  return (
    <Grid.Column>
      <Grid.Row key="courses">
        <Header size="huge">Courses</Header>
      </Grid.Row>
      {departmentCourses.map(({ courseId, courseName, courseCode }) => {
        return (
          <Grid.Row className="list-item" key={`course_${courseId}`}>
            <CourseDisplayLink courseCode={courseCode} courseId={courseId} courseName={courseName} />
          </Grid.Row>
        );
      })}
    </Grid.Column>
  );
}

const propTypesDepartmentProfessors = {
  departmentProfessors: PropTypes.arrayOf(
    PropTypes.shape({
      professorId: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      badges: PropTypes.arrayOf(PropTypes.number).isRequired,
      nugget: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export function DepartmentProfessorColumn({ departmentProfessors }) {
  return (
    <Grid.Column>
      <Grid.Row key="professors">
        <Header size="huge">Professors</Header>
      </Grid.Row>
      {departmentProfessors.map(
        ({ professorId, firstName, lastName, nugget, badges }) => {
          return (
            <Grid.Row className="list-item" key={`professor_${professorId}`}>
              <ProfessorDisplayLink
                badges={badges}
                firstName={firstName}
                lastName={lastName}
                nugget={nugget}
                professorId={professorId}
              />
            </Grid.Row>
          );
        }
      )}
    </Grid.Column>
  );
}

const propTypesDepartmentInfo = {
  departmentName: PropTypes.string.isRequired,
  departmentCourses: PropTypes.arrayOf(
    PropTypes.shape({
      courseId: PropTypes.number.isRequired,
      courseName: PropTypes.string.isRequired,
      courseCode: PropTypes.string.isRequired,
    })
  ).isRequired,
  departmentProfessors: PropTypes.arrayOf(
    PropTypes.shape({
      professorId: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      nugget: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export function DepartmentInfo({
  departmentCourses,
  departmentName,
  departmentProfessors,
}) {
  return (
    <>
      <PageHeader>
        <DepartmentDisplayName
          as="header"
          departmentName={departmentName}
          size="huge"
        />
        <LinkContainer>
          <Link to='/departments/all'>Back to All Departments</Link>
        </LinkContainer>
      </PageHeader>
      <Divider />
      <Grid stackable columns={2}>
        <DepartmentProfessorColumn
          departmentProfessors={departmentProfessors}
        />
        <DepartmentCourseColumn departmentCourses={departmentCourses} />
      </Grid>
    </>
  );
}

const LinkContainer = styled.div`
  margin: auto 0 auto auto;
  font-weight: 600;
`

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
`

export default function DepartmentInfoPage() {
  const { departmentId } = useParams();
  const {
    data: { departmentName, departmentCourses, departmentProfessors },
    isLoading,
    isError,
  } = useDataFetch(`/api/department/${departmentId}`, {
    departmentName: "",
    departmentCourses: [],
    departmentProfessors: [],
  });

  if (isLoading || isError) {
    return isLoading ? <LoadingComponent /> : <ErrorComponent />;
  }

  return (
    <DepartmentInfo
      departmentCourses={departmentCourses}
      departmentName={departmentName}
      departmentProfessors={departmentProfessors}
    />
  );
}

DepartmentCourseColumn.propTypes = propTypesDepartmentCourses;

DepartmentProfessorColumn.propTypes = propTypesDepartmentProfessors;

DepartmentInfo.propTypes = propTypesDepartmentInfo;

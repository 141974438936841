import React, {useEffect} from "react";
import ReactGA from 'react-ga';
import { Route, HashRouter as Router, Switch } from "react-router-dom";

import AdminPortalPage from "components/AdminPortalPage";
import AnnouncementsPage from "components/AnnouncementsPage";
import { AuthProvider, ProtectedRoute } from "components/common/Authentication";
import ScrollToTop from "components/common/ScrollToTop";
import CourseInfoPage from "components/CourseInfoPage";
import CreateReviewPage from "components/CreateReviewPage";
import DepartmentInfoPage from "components/DepartmentInfoPage";
import DepartmentsPage from "components/DepartmentsPage";
import HomePage from "components/HomePage";
import AboutUsPage from "components/info-pages/AboutUsPage";
import FAQsPage from "components/info-pages/FAQsPage";
import JoinTheTeamPage from "components/info-pages/JoinTheTeamPage";
import PrivacyPolicyPage from "components/info-pages/PrivacyPolicyPage";
import TermsOfUsePage from "components/info-pages/TermsOfUsePage";
import LoginPage from "components/LoginPage";
import NavigationBar from "components/NavigationBar";
import NewestReviewsPage from "components/NewestReviewsPage";
import ProfessorInfoPage from "components/ProfessorInfoPage";
import SearchResultsPage from "components/SearchResultsPage";
import SingleReviewPage from "components/SingleReviewPage";

// For Google Analytics
const TRACKING_ID = "G-3BWHP4ZKYH";
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    // For google analytics - tracks visits to individual pages
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <NavigationBar>
          <Switch>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <ProtectedRoute exact path="/admin">
              <AdminPortalPage />
            </ProtectedRoute>
            <Route exact path="/about">
              <AboutUsPage/>
            </Route>
            <Route exact path="/join-the-team">
              <JoinTheTeamPage />
            </Route>
            <Route exact path="/faqs">
              <FAQsPage />
            </Route>
            <Route exact path="/privacy">
              <PrivacyPolicyPage />
            </Route>
            <Route exact path="/terms-of-use">
              <TermsOfUsePage />
            </Route>
            <Route exact path="/announcements">
              <AnnouncementsPage />
            </Route>
            <Route exact path="/newestReviews">
              <NewestReviewsPage />
            </Route>
            <Route exact path="/review/submit">
              <CreateReviewPage />
            </Route>
            <Route path="/review/:reviewId">
              <SingleReviewPage />
            </Route>
            <Route path="/professor/:professorId">
              <ProfessorInfoPage />
            </Route>
            <Route path="/course/:courseId">
              <CourseInfoPage />
            </Route>
            <Route path="/departments">
              <DepartmentsPage />
            </Route>
            <Route path="/department/:departmentId">
              <DepartmentInfoPage />
            </Route>
            <Route path="/search">
              <SearchResultsPage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </NavigationBar>
      </Router>
    </AuthProvider>
  );
}

export default App;

/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Dropdown, Button, TextArea, Input, Form, FormField } from "semantic-ui-react";

async function submitQuoteToServer(quote) {
  try {
    const response = await fetch("/api/quote/submit", {
      method: "POST",
      body: JSON.stringify(quote),
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    return await response.json(); // Return the parsed JSON data
  } catch (error) {
    return error;
  }
}
  
// Define propTypes for SubmitQuoteForm component
const propTypesSubmitQuoteForm = {
courses: PropTypes.arrayOf(
    PropTypes.shape({
    courseId: PropTypes.number.isRequired,
    courseName: PropTypes.string.isRequired,
    courseCode: PropTypes.string.isRequired,
    courseProfessorId: PropTypes.number.isRequired,
    })
).isRequired, // Add isRequired to ensure the prop is provided
firstName: PropTypes.string.isRequired, // Add prop type validation for firstName
lastName: PropTypes.string.isRequired, // Add prop type validation for lastName
onCloseModal: PropTypes.func.isRequired,
onOpenThankYouModal: PropTypes.func.isRequired
};

export default function SubmitQuoteForm ({ courses, firstName, lastName, onCloseModal, onOpenThankYouModal }) {
    const [quote, setQuote] = useState({
      course_professor_id: '',
      content: ''
    });
    const [errors, setErrors] = useState({});

    // const [quoteID, setQuoteID] = useState('');
  
    const dropdownOptions = courses.map(course => ({
      key: course.courseId,
      value: course.courseProfessorId,
      text: `${course.courseCode} - ${course.courseName}`
    }));
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setQuote(prevQuote => ({
        ...prevQuote,
        [name]: value
      }));
    };
  
    const handleDropdownChange = (event, data) => {
      setQuote(prevQuote => ({
        ...prevQuote,
        course_professor_id: data.value 
      }));
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const newErrors = {};
  
      // Trim the quote content to remove leading and trailing whitespace
      const trimmedContent = quote.content.trim();
  
      // Check if the trimmed content is empty
      if (!trimmedContent) {
        newErrors.content = 'Please provide a quote or impression before submitting.';
      }
  
      // Check if a course is selected
      if (!quote.course_professor_id) {
        newErrors.course = 'Please select a course before submitting.';
      }
  
      // Count words in the content
      const wordCount = quote.content.split(/\s+/).length;
      if (wordCount > 75) {
        newErrors.content = 'Word limit exceeded. Please limit your quote to 75 words.';
      }
  
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
  
     
      const response = await submitQuoteToServer(quote);
      onOpenThankYouModal();
      onCloseModal(response);
    };
  
  
    return (
      <>
        <Form onSubmit={handleSubmit}>
          <FormField  error={errors.professor}>
            <label htmlFor="professor-name">Professor:</label>
            <Input
                id="professor-name"
                name="professor name"
                placeholder={`${firstName} ${lastName}`}
                type="text"
                value={`${firstName} ${lastName}`}
                onChange={handleInputChange}
              />
          </FormField>
          <FormField error={errors.course}>
            <label htmlFor="dropdown">Course:</label>
            <Dropdown
                fluid
                selection
                id="dropdown"
                options={dropdownOptions}
                placeholder='Choose Below'
                rules={{ required: "Please select a matching course" }}
                onChange={handleDropdownChange}
              />
          </FormField>
          <FormField error={errors.content}>
            <label htmlFor="content">Quote (75 words max):</label>
            <TextArea
                id="content"
                name="content"
                placeholder="Quote..."
                rules={{ required: "Please input a quote/impression" }}
                style={{ height: "10rem", resize: "none", borderColor: quote.content.split(/\s+/).length > 75 ? "red" : null}}
                type="text"
                value={quote.content}
                onChange={handleInputChange}
              />
          </FormField>
          <Button className="submitQuote" name="submit" type="submit" >
            Submit Quote
          </Button>
        </Form>
      </>
    );
  }

  SubmitQuoteForm.propTypes = propTypesSubmitQuoteForm;
import React, { useEffect, useState } from "react";
import { Container, Divider, Header } from "semantic-ui-react";

import ActionMenu from './ActionMenu';

import ReviewCard from "components/reviews/ReviewCard";

const ACCEPT = "Accept Review";
const LIBEL = "Mark as Libel";

const ReviewsList = () => {
    const [reviews, setReviewsList] = useState([]);
    const [reviewCount, setReviewCount] = useState(0);

    const fetchReviews = () => {
        fetch(`/api/review/get/pending`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                throw response;
            })
            .then(response => response.json())
            .then(json => {
                setReviewsList(json.reviews)
                setReviewCount(json.reviews.length)
            })
            .catch(error => {return error})
    }

    const updateReview = async (reviewId, status) => {
        let newStatus = 'pending'
        if (status === ACCEPT) {
            newStatus = 'approved'
        } else if (status === LIBEL) {
            newStatus = 'libel'
        }

        const response = await fetch(`/api/review/put/${reviewId}/${newStatus}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
        });
        try {
            const result = await response.json();
            fetchReviews()

            return result
        } catch (err) {
            return { error: err };
        }
    };

    useEffect(() => {
        fetchReviews()
    }, [])

    return (
        <Container>
            <Header size="large">{reviewCount} Reviews</Header>
            <Container>
                <Header size="medium">Pending Reviews:</Header>
                {reviews && reviews.map(review => (
                    <Container key={review.reviewId}>
                        <ReviewCard
                            content={review.content}
                            deprecated={review.deprecated}
                            reviewHeader={review.reviewHeader}
                            reviewId={review.reviewId}
                            reviewType={review.reviewType}
                            submissionDate={review.submissionDate}
                            workload={review.workload}
                        />
                        <ActionMenu handleSubmit={updateReview} itemId={review.reviewId} options={[ACCEPT, LIBEL]}/>
                        <Divider />
                    </Container>
                ))}
            </Container>
        </Container>
    )
}

export default ReviewsList;

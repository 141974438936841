import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import { Container, Header, Accordion, Icon, Modal, Button } from "semantic-ui-react";
import styled from "styled-components";

import SyllabusLink from "./common/SyllabusLink";
import SubmitSyllabusForm from "./SubmitSyllabusForm";

const propTypesGetSyllabi = {
  courseProfId: PropTypes.number.isRequired,
  isProfessor: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
    courseId: PropTypes.number.isRequired,
    courseName: PropTypes.string.isRequired,
    courseCode: PropTypes.string.isRequired,
    courseProfessorId: PropTypes.number.isRequired,
    })
  ).isRequired, 
  courseCode: PropTypes.number.isRequired,
  courseName: PropTypes.string.isRequired,
  courseProfessors: PropTypes.arrayOf(
    PropTypes.shape({
        professorID: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        courseProfessorId: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default function GetSyllabi
    ({
    courseProfId = -1, // Default value for courseProfId
    isProfessor = true, // Default value for isProfessor
    firstName = null,
    lastName = null,
    courses = null,
    courseName=null,
    courseProfessors=null,
    courseCode=null
    }) {
  const [syllabi, setSyllabiList] = useState([]);
  const [isAccordionActive, setAccordionActive] = useState(false);
  const [syllabusModalOpen, setSyllabusModalOpen] = useState(false);
  const [thankYouModalOpen, setThankYouModalOpen] = useState(false);
  const [syllabusID, setSyllabusID] = useState(null);

  const fetchSyllabi = useCallback(() => {
    const apiUrl = isProfessor
      ? `/api/sillabi/get/professor/${courseProfId}`
      : `/api/sillabi/get/course/${courseProfId}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        }
        throw response;
      })
      .then((response) => response.json())
      .then((json) => {
        setSyllabiList(json.sillabi);
      })
      .catch((error) => {
        return error;
      });
  }, [courseProfId, isProfessor]);

  useEffect(() => {
    fetchSyllabi();
  }, [courseProfId, isProfessor, fetchSyllabi]);

  const handleCloseSyllabusModal = (response) => {
    setSyllabusID(response.syllabus_id); 
    setSyllabusModalOpen(false);
  };

  const handleCloseThankYouModal = () => {
    setThankYouModalOpen(false);
  };

  const handleOpenThankYouModal = () => {
    setThankYouModalOpen(true);
  };


  if (!isProfessor)
  {
  return (
    <Container>
      <Container>
        <Accordion className="show-syllabi">
        <Accordion.Title
          active={isAccordionActive}
          onClick={() => setAccordionActive(!isAccordionActive)}
        >
        <CourseSyllabi>
        <div>
          <Icon name={!isAccordionActive ? "angle down" : "angle up"} />
          <Header className="no-margin">{!isAccordionActive ? "Show" : "Hide"} Syllabi</Header>
        </div>

        <Button onClick={() => setSyllabusModalOpen(true)}>Submit Syllabus</Button>
        </CourseSyllabi>
      
      </Accordion.Title>
      <Accordion.Content active={isAccordionActive}>
        {syllabi &&
          syllabi.map((syllabus) => (
            <Container key={syllabus.syllabus_id}>
              <SyllabusLink
                style={{ marginBottom: "1rem" }}
                syllabus={{
                  syllabusId: syllabus.syllabus_id,
                  profFirstName: syllabus.first_name,
                  profLastName: syllabus.last_name,
                  courseName: syllabus.course_name,
                  courseCode: syllabus.course_code,
                }}
              />
            </Container>
          ))}
          </Accordion.Content>
        </Accordion>
      </Container>

      <Modal
        className="submit-quote"
        open={syllabusModalOpen}
        onClose={() => setSyllabusModalOpen(false)}
        onOpen={() => setSyllabusModalOpen(true)}
        >
        <Modal.Header>
          Upload Syllabus
          <Button className="quote" onClick={() => setSyllabusModalOpen(false)}><Icon name = 'cancel'/></Button>
        </Modal.Header>

        <Modal.Content>
          <SubmitSyllabusForm 
          courseCode={courseCode}
          courseID ={courseProfId} 
          courseName={courseName} 
          isProfessor={false}
          professors={courseProfessors}
          onCloseModal={handleCloseSyllabusModal}
          onOpenThankYouModal={handleOpenThankYouModal}
          />
        </Modal.Content>
      </Modal>
      <Modal
        className="thank-you-modal"
        open={thankYouModalOpen}
        onClose={() => setThankYouModalOpen(false)}
      >
        <Modal.Header>Thank You!</Modal.Header>
        <Modal.Content>
          <p>Please save your <span style={{color: "#FDC636"}}>Syllabus ID: {syllabusID} </span> in case you need to refer to your submission in the future.</p>
        </Modal.Content>
        <Button className="closeQuote" onClick={handleCloseThankYouModal}>Return to previous page</Button>
      </Modal>

    </Container>
  );

  }

    return (
      <Container>
      <Container>
        <Accordion className="show-syllabi">
        <Accordion.Title
          active={isAccordionActive}
          onClick={() => setAccordionActive(!isAccordionActive)}
        >
        
        <ProfessorSyllabi>
          <SyllabusToggle>
            <Icon name={!isAccordionActive ? "angle down" : "angle up"} />
            <Header className="no-margin">{!isAccordionActive ? "Show" : "Hide"} Syllabi</Header>
          </SyllabusToggle>
          <Button onClick={() => setSyllabusModalOpen(true)}>Submit Syllabus</Button>
        </ProfessorSyllabi>
          
      </Accordion.Title>
      <Accordion.Content active={isAccordionActive}>
        {syllabi &&
          syllabi.map((syllabus) => (
            <Container key={syllabus.syllabus_id}>
              <SyllabusLink
                style={{ marginBottom: "1rem" }}
                syllabus={{
                  syllabusId: syllabus.syllabus_id,
                  profFirstName: "",
                  profLastName: "",
                  courseName: syllabus.course_name,
                  courseCode: syllabus.course_code,
                }}
              />
            </Container>
          ))}
          </Accordion.Content>
        </Accordion>
      </Container>
    

      <Modal
      className="submit-quote"
      open={syllabusModalOpen}
      onClose={() => setSyllabusModalOpen(false)}
      onOpen={() => setSyllabusModalOpen(true)}
      >
      <Modal.Header>
        Upload Syllabus
        <Button className="quote" onClick={() => setSyllabusModalOpen(false)}><Icon name = 'cancel'/></Button>
      </Modal.Header>

      <Modal.Content>
        <SubmitSyllabusForm 
          courses={courses} 
          firstName={firstName} 
          isProfessor={isProfessor}
          lastName={lastName}

          onCloseModal={handleCloseSyllabusModal}
          onOpenThankYouModal={handleOpenThankYouModal}
        />
      </Modal.Content>
      </Modal>

      
      <Modal
        className="thank-you-modal"
        open={thankYouModalOpen}
        onClose={() => setThankYouModalOpen(false)}
      >
        <Modal.Header>Thank You!</Modal.Header>
        <Modal.Content>
          <p>Please save your <span style={{color: "#FDC636"}}>Syllabus ID: {syllabusID} </span> in case you need to refer to your submission in the future.</p>
        </Modal.Content>
        <Button className="closeQuote" onClick={handleCloseThankYouModal}>Return to previous page</Button>
      </Modal>
      
    </Container>
    );
  }

const CourseSyllabi = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProfessorSyllabi = styled.div`
  display: flex;
  align-items: center;
`;

const SyllabusToggle = styled.div`
  margin-right: 3rem;
`

GetSyllabi.propTypes = propTypesGetSyllabi;

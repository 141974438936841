import React from "react";
import { Link } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";
import styled from "styled-components";

export default function PrivacyPolicyPage() {

  return (
    <>
      <Header size="huge">PRIVACY POLICY</Header>
      <ContentContainer>
        <p>This is the privacy policy (&quot;Privacy Policy&quot;) describing our privacy practices for the  CULPA.info website, applications and other interactive services (&quot;Site,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;). The Site is owned, operated and/or provided by Spectator Publishing Company (&quot;Company&quot;, &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;).</p>
        <p>This Privacy Policy applies:</p>
        <Container>
          <li>Every time you access the Site, regardless of device;</li>
          <li>Whether you are accessing the Site as a registered user (if offered by the Site) or other user of the Site;</li>
          <li>To all information collected by the Site;</li>
          <li>To our use of combined information if we combine Information collected by the Site with other information we collect from other sources, such as information received from the Company (for example, information collected at a physical location), marketing companies or Advertisers; and</li>
          <li>Will remain in full force and effect even if your use of or participation in the Site or any particular service, feature, function or promotional activity offered  through the Site, you consent to our privacy practices as described in this Privacy Policy.</li>
        </Container>
        <Header size="small">What information do we collect?</Header>
        <p>We collect information from you when you submit content to our site, for example when you submit User Contributions to the Site and we store that content in our database and share it with other users of the Site.</p>
        <p>The Company is in no way responsible for the accuracy, use, or misuse of any information, including personal information, that you disclose or receive through the Site. If you post any User Contribution to the Site, please remember that any information included in such User Contributions is automatically made public and please consider when disclosing any personal information in your User Contributions that you do not know who may access and use user contribution or for what purpose.</p>
        <p>We ask for your email address to use the Google Forms service to receive comments on the Site.</p>
        <p>We also collect users&apos; IP addresses (anonymously, and used in aggregated databases) for analytics purposes.</p>
        <Header size="small">What do we use your information for?</Header>
        <p>Any information we collect from you may be used in one of the following ways:</p>
        <Container>
          <li>To improve the Site (we continually strive to improve our website offerings based on the information and feedback we receive from you), and other websites and products of the Company;</li>
          <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs); and</li>
          <li>To process transactions that you have specifically requested.</li>
        </Container>
        <p>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested (if any).</p>
        <Header size="small">Do we use cookies?</Header>
        <p>We do not use cookies on the Site.</p>
        <Header size="small">Do we disclose any information to outside parties?</Header>
        <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information other than for the express purpose of delivering the purchased product or service requested. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety.</p>
        <Header size="small">Third-Party Links</Header>
        <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
        <Header size="small">California Online Privacy Protection Act Compliance</Header>
        <p>Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent except as permitted by this policy or by law.</p>
        <Header size="small">Age Requirement to Use the Site</Header>
        <p>You must be at least 17 years of age to use the Site.</p>
        <Header size="small">Online Privacy Policy Only</Header>
        <p>This online privacy policy applies only to information collected through our website and not to information collected offline.</p>
        <Header size="small">Your Consent</Header>
        <p>By using our site, you consent to this privacy policy.</p>
        <Header size="small">U.S. Governing Law</Header>
        <p>The Site is designed and targeted to U.S. audiences and is governed by and operated in accordance with the laws of the U.S. We make no representation that this Site is operated in accordance with the laws or regulations of, or governed by, other nations. If you are located outside of the U.S., you use this Site at your own risk and initiative and you, not us, are responsible for compliance with any applicable local and national laws. Please be aware that any Personal Information and Other Information you provide to us or we obtain as a result of your use of this Site shall be collected in the U.S. and/or transferred to the U.S. and subject to U.S. law. By using this Site, participating in any Site activities and/or providing us with your Personal Information and Other Information, you (a) consent to the transfer and/or processing of any Information to and in the U.S., (b) acknowledge that U.S. law may provide a lower standard of protection for personal data than the laws of your location and (c) understand that we shall collect, transfer, store, process and/or deal with your Information in accordance with this Privacy Policy and U.S. law. Consequently, to the full extent permitted by law, you hereby waive any claims relating to the processing of your Personal Information or Other Information in accordance with this Privacy Policy that may arise under the laws and regulations that apply to you in or of any other country or jurisdiction.</p>
        <Header size="small">Changes to our Privacy Policy</Header>
        <p>We reserve the right to revise this Privacy Policy at any time for any reason in our sole discretion by posting an updated Privacy Policy without advance notice to you. Such revisions shall be effective immediately upon posting and if you use the Site after they become effective it will signify your agreement to be bound by the changes. We shall post or display notices of material changes on the Site&apos;s homepage and/or otherwise on the Site and/or e-mail you or notify you upon login about these changes; the form of such notice is at our discretion. However, we encourage you to check this Privacy Policy and <Link to='/terms-of-use'>Terms of Use Agreement</Link> often for updates.</p>
        <p>This policy was last modified on November 15, 2022.</p>
      </ContentContainer>
    </>
  );
}

const ContentContainer = styled.div`
  p {
    margin: 0.5rem 0rem;
  }

  li {
    margin-left: 4rem;
  }
`

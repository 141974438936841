import React, { useState } from 'react'
import { Button, Header } from 'semantic-ui-react';

import CoursesList from './admin-portal/CoursesList';
import SyllabiList from './admin-portal/PDFList';
import ProfessorsList from './admin-portal/ProfessorsList';
import QuotesList from './admin-portal/QuotesList';
import ReviewsList from './admin-portal/ReviewsList';




export default function AdminPortalPage() {
    const Tabs = ["Reviews", "Courses", "Professors", "Course Professor Relationships", "Quotes", "PDFs"]

    const [tabSelected, setTab] = useState("Reviews")

    const CurrentTab = () => {
        switch (tabSelected) {
            case "Reviews":
                return <ReviewsList />
            case "Courses":
                return <CoursesList />
            case "Professors":
                return <ProfessorsList />
            case "Course Professor Relationships":
                return <div>Course Professor Relationships</div>
            case "Quotes":
                return <QuotesList />
            case "PDFs":
                return <SyllabiList />
            default:
                return <ReviewsList />
        }
    }

    return (
        <>
            <Header size='huge'>Admin Dashboard - Pending Items</Header>
            <br/>
            {Tabs.map(tab => (
                <Button basic active={tabSelected === tab} tabIndex={0} onClick={() => setTab(tab)} onKeyDown={() => setTab(tab)}>{tab}</Button>
            ))}
            <br/>
            <CurrentTab />
        </>
    )
}

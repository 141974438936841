import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Divider, Grid, Header } from "semantic-ui-react";

import ActionMenu from './ActionMenu';

const ACCEPT = "Accept Quote";
const REJECT = "Reject Quote";

export default function QuotesList() {
    const [quotes, setQuotesList] = useState([]);
    const [quoteCount, setQuoteCount] = useState(0);

    const fetchQuotes = () => {
        fetch(`/api/quote/get/pending`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                throw response;
            })
            .then(response => response.json())
            .then(json => {
                setQuotesList(json.quotes)
                setQuoteCount(json.quotes.length)
            })
            .catch(error => { return error })
    }

    const updateQuote = async (quoteId, status) => {
        let newStatus = 'pending'
        if (status === ACCEPT) {
            newStatus = 'approved'
        } else if (status === REJECT) {
            newStatus = 'rejected'
        }

        const response = await fetch(`/api/quote/put/${quoteId}/${newStatus}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" }
        });
        try {
            const result = await response.json();
            fetchQuotes()

            return result
        } catch (err) {
            return { error: err };
        }
    };

    useEffect(() => {
        fetchQuotes()
    }, [])

    return (
        <Container>
            <Header size="large">{quoteCount} Quotes </Header>
            <Container>
                <Header size="medium">Pending Quotes:</Header>
                {quotes && quotes.map((quote) => (
                    <QuoteCard quote={quote} updateQuote={updateQuote}/>
                ))}
            </Container>
        </Container>
    )
}

const propTypesQuoteCard = {
    quote: PropTypes.shape({
        quoteId: PropTypes.number.isRequired,
        content: PropTypes.string.isRequired,
        quoteDate: PropTypes.instanceOf(Date).isRequired,
        firstName:  PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        courseName: PropTypes.string.isRequired,
        courseCode: PropTypes.string.isRequired
    }).isRequired,
    updateQuote: PropTypes.func.isRequired
};

const QuoteCard = ({ quote, updateQuote }) => {
    const formatDate = (date) => {
        if (!date) return ''; // Handle case when date is undefined or null
        const isoString = new Date(date).toISOString(); // Convert date to ISO string
        return isoString.split('T')[0]; // Extract yyyy-mm-dd part
    };

    return (
        <Container>
            <Container className="course-card">
                <Grid.Column>
                    <Header className="no-margin review-card-header">{quote.firstName}{" "}{quote.lastName}</Header>
                    <br />
                    <Header size="small">{quote.content === "" || !quote.content ? "No Content" : quote.content}</Header>
                    <p>Course: {quote.courseName === "" || !quote.courseName ? "No Course Name" : quote.courseName}</p>
                    <p>Course Code: {quote.courseCode}</p>
                    <p>Quote&apos;s Date: {formatDate(quote.quoteDate)}</p>
                </Grid.Column>
            </Container>
            <ActionMenu handleSubmit={updateQuote} itemId={quote.quoteId} options={[ACCEPT, REJECT]} />
            <Divider />
        </Container>
    )
}

QuoteCard.propTypes = propTypesQuoteCard;